import promise from "bluebird"
import { usersConstants } from "../_constants"
import { usersService } from "../_services"
import { ResponseFilter } from "../config/response-handler"

export const farmActions = {
  addFarm,
  editFarm,
  getFarmDetails,
  editMedia,
  deleteMedia,

  // USPS API
  verifyAddress,
  domesticRate,
  domesticPackageLabel,
  trackDomesticPackage,

  // deleteMedia
}

function addFarm(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      usersService
        .addFarm(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }

  function request(addfarmRequest) {
    return { type: usersConstants.ADD_FARM_REQUEST, addfarmRequest }
  }
  function success(addfarmSuccess) {
    return { type: usersConstants.ADD_FARM_SUCCESS, addfarmSuccess }
  }
  function failure(addfarmFail) {
    return { type: usersConstants.ADD_FARM_ERROR, addfarmFail }
  }
}

function getFarmDetails(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      usersService
        .getFarmDetails(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }

  function request(getFarmDetailsRequest) {
    return { type: usersConstants.GET_FARM_REQUEST, getFarmDetailsRequest }
  }
  function success(getFarmDetailsSuccess) {
    return { type: usersConstants.GET_FARM_SUCCESS, getFarmDetailsSuccess }
  }
  function failure(getFarmDetailsFail) {
    return { type: usersConstants.GET_FARM_ERROR, getFarmDetailsFail }
  }
}
function editFarm(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      usersService
        .editFarm(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }

  function request(editfarmRequest) {
    return { type: usersConstants.EDIT_FARM_REQUEST, editfarmRequest }
  }
  function success(editfarmSuccess) {
    return { type: usersConstants.EDIT_FARM_SUCCESS, editfarmSuccess }
  }
  function failure(editfarmFail) {
    return { type: usersConstants.EDIT_FARM_ERROR, editfarmFail }
  }
}

function editMedia(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      usersService
        .editMedia(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }

  function request(editMediaRequest) {
    return { type: usersConstants.EDIT_MEDIA_REQUEST, editMediaRequest }
  }
  function success(editMediaSuccess) {
    return { type: usersConstants.EDIT_MEDIA_SUCCESS, editMediaSuccess }
  }
  function failure(editMediaFail) {
    return { type: usersConstants.EDIT_MEDIA_ERROR, editMediaFail }
  }
}

function deleteMedia(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request(params))
      usersService
        .deleteMedia(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }

  function request(deleteMediaRequest) {
    return { type: usersConstants.DELETE_MEDIA_REQUEST, deleteMediaRequest }
  }
  function success(deleteMediaSuccess) {
    return { type: usersConstants.DELETE_MEDIA_SUCCESS, deleteMediaSuccess }
  }
  function failure(deleteMediaFail) {
    return { type: usersConstants.DELETE_MEDIA_ERROR, deleteMediaFail }
  }
}

function verifyAddress(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request())
      usersService
        .verifyAddress(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }

  function request(verifyAddressRequest) {
    return { type: usersConstants.VERIFY_ADDRESS_REQUEST, verifyAddressRequest }
  }
  function success(verifyAddressSuccess) {
    return { type: usersConstants.VERIFY_ADDRESS_SUCCESS, verifyAddressSuccess }
  }
  function failure(verifyAddressFail) {
    return { type: usersConstants.VERIFY_ADDRESS_ERROR, verifyAddressFail }
  }
}

function domesticRate(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request())
      usersService
        .domesticRate(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }

  function request(domesticRateRequest) {
    return { type: usersConstants.DOMESTIC_RATE_REQUEST, domesticRateRequest }
  }
  function success(domesticRateRequestSuccess) {
    return {
      type: usersConstants.DOMESTIC_RATE_SUCCESS,
      domesticRateRequestSuccess,
    }
  }
  function failure(domesticRateRequestFail) {
    return {
      type: usersConstants.DOMESTIC_RATE_ERROR,
      domesticRateRequestFail,
    }
  }
}

function domesticPackageLabel(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request())
      usersService
        .domesticPackageLabel(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }

  function request(domesticPackageLabelRequest) {
    return {
      type: usersConstants.DOMESTIC_PACKAGE_LABEL_REQUEST,
      domesticPackageLabelRequest,
    }
  }
  function success(domesticPackageLabelRequestSuccess) {
    return {
      type: usersConstants.DOMESTIC_PACKAGE_LABEL_SUCCESS,
      domesticPackageLabelRequestSuccess,
    }
  }
  function failure(domesticPackageLabelRequestFail) {
    return {
      type: usersConstants.DOMESTIC_PACKAGE_LABEL_ERROR,
      domesticPackageLabelRequestFail,
    }
  }
}

function trackDomesticPackage(params) {
  return function (dispatch) {
    return new promise(function (resolve, reject) {
      dispatch(request())
      usersService
        .trackDomesticPackage(params)
        .then((response) => {
          let { serverResponseData } = ResponseFilter(response)
          resolve(serverResponseData)
          dispatch(success(serverResponseData))
        })
        .catch((err) => {
          reject(err)
          dispatch(failure(err))
        })
    })
  }

  function request(trackDomesticPackageRequest) {
    return {
      type: usersConstants.TRACK_DOMESTIC_PACKAGE_REQUEST,
      trackDomesticPackageRequest,
    }
  }
  function success(trackDomesticPackageRequestSuccess) {
    return {
      type: usersConstants.TRACK_DOMESTIC_PACKAGE_SUCCESS,
      trackDomesticPackageRequestSuccess,
    }
  }
  function failure(trackDomesticPackageRequestFail) {
    return {
      type: usersConstants.TRACK_DOMESTIC_PACKAGE_ERROR,
      trackDomesticPackageRequestFail,
    }
  }
}
