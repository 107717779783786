export default {
  api: {
    url: "https://stageapi.healthyplaneat.com/healthyplaneat/api/v1/",

    mode: "cors",
  },
  config: {
    privacy_policy: "privacypolicies",
    terms_services: "termsservices",
    client_id: "ca_HUzvFfSTp2czAlo5h2MchSNvRczV6htB",
    farmerStripeRedirectUrl:
      "https://stagefarmer.healthyplaneat.com/account-oauth-token",
    hostStripeRedirectUrl:
      "https://stagehost.healthyplaneat.com/account-oauth-token",
  },
}
