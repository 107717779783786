import _ from "lodash"

import DevSetting from "./development"
import ProdSetting from "./production"
import StagSetting from "./staging"
import localhostSetting from "./localhost"

let defaultSetting = {
  api: {
    url: "http://localhost:5004/healthyplaneat/api/v1/",
    mode: "cors",
  },
}

let siteSetting = defaultSetting

switch (process.env.REACT_APP_ENV) {
  case "prod":
  case "production":
    siteSetting = _.extend(defaultSetting, ProdSetting)
    break
  case "stag":
  case "staging":
    siteSetting = _.extend(defaultSetting, StagSetting)
    break
  case "dev":
  case "development":
    siteSetting = _.extend(defaultSetting, DevSetting)
    break
  case "local":
  case "localhost":
    siteSetting = _.extend(defaultSetting, localhostSetting)
    break
  default:
    siteSetting = _.extend(defaultSetting, StagSetting)
}

switch (process.env.REACT_APP_USER_TYPE) {
  case "host":
    siteSetting = _.extend(siteSetting, { isHost: true })
    break
  case "farmer":
  default:
    siteSetting = _.extend(siteSetting, { isHost: true })
    break
}

export default siteSetting
