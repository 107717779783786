import React, { Component } from "react"
import { usersActions } from "../../_actions"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import { getUserType } from "../../util/user"
import Sidebar from "../../components/sidebar"
import "react-phone-input-2/lib/style.css"

const queryString = require('querystring'); 
class AccountToken extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errors: "",
    }
  }

  componentDidMount() {
    this.addAuthInfo()
  }

  addAuthInfo = () => {
    this.setState({ errors: "" })

    let { dispatch } = this.props

    let self = this
    self.setState({ loader: true })
    const parsed = queryString.parse(this.props.location.search)
    const code = this.props.location.search.split("&")[0].split("=")[1]
    let obj = {
      code: code,
      userType: getUserType(),
    }
    dispatch(usersActions.accountOauthToken(obj))
      .then((data) => {
        // self.setState({loader: false})
        if (data.statusCode == 1) {
          if (data.error) {
            self.setState({ loader: false })
            self.setState({
              errors: data.error.responseMessage.responseMessage,
            })
          } else {
            self.props.history.push("/settings")
          }
        } else {
          self.setState({ loader: false })
          self.setState({ errors: data.error.responseMessage.responseMessage })
        }
      })
      .catch((err) => {
        self.setState({ loader: false })
        self.setState({ errors: err.error.responseMessage.responseMessage })
      })

    // }
  }

  render() {
    let { errors } = this.state
    return (
      <>
        <Sidebar />

        <div>
          <div className="header_wrapper">
            <div className="header_logo">
              <img src={require("../../assets/img/OFFICIAL LOGO.png")} />
            </div>
            <div className="header_search"></div>
            <div className="bell_icon">
              <i className="fas fa-bell"></i>
            </div>
          </div>

          <div className="body_container">
            <div className="body_inner_wrapper">
              {this.state.loader ? (
                <div className="loader_wrapper">
                  <div class="spinner-border text-info" role="status"></div>
                </div>
              ) : (
                <>
                  <div className="loader_wrapper1">
                    <div className="loader_wrapperinner">
                      <div className="accounttokenerror" role="status">
                        <div className="error1">{errors}</div>
                      </div>
                      <span>
                        <Link
                          to="/settings"
                          // type="submit"
                          // redirectTo
                          className=" accounttokenback btn btn-outline-info"
                          variant="warning"
                        >
                          Back
                        </Link>
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  const { props, mapReducer } = state
  return {
    props,
    mapReducer,
  }
}

export default connect(mapStateToProps)(AccountToken)
// export default Settings
