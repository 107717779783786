import axios from "axios"
import siteSetting from "./env"

export default {
  AUTH: "Basic aGVhbHRoeVBsYW5ldF9hZG1pbl92WnBHaDQ6YWRtaW5AaGVhbHRoeVBsYW5ldF9GVGtWcFE=",
  SIGN_UP: siteSetting.api.url + "user/signup",
  FORGOT: siteSetting.api.url + "user/forgetpassword",
  LOGIN: siteSetting.api.url + "user/login ",
  RESET: siteSetting.api.url + "user/resetpassword",
  GET_USER_INFO: siteSetting.api.url + "user/getProfile",
  EDIT_PROFILE: siteSetting.api.url + "user/editProfile",
  ADD_ADDRESS: siteSetting.api.url + "user/addAddress",
  EDIT_ADDRESS: siteSetting.api.url + "user/editAddress",
  UPLOAD_DOC: siteSetting.api.url + "user/uploadDocument",
  CHANGE_PASSWORD: siteSetting.api.url + "user/changpassword",
  AUTH_TOKEN: siteSetting.api.url + "user/accountOauthToken",
  GET_INVENTORY_LIST: siteSetting.api.url + "inventory/getInv",
  GET_INV_LIST: siteSetting.api.url + "items/getItems",
  ADD_INVENTORY_ITEM: siteSetting.api.url + "inventory/create",
  EDIT_INVENTORY_ITEM: siteSetting.api.url + "inventory/update",
  ADD_FARM: siteSetting.api.url + "farm/create",
  EDIT_FARM: siteSetting.api.url + "farm/update",
  EDIT_MEDIA: siteSetting.api.url + "farm/updateMedia",
  DELETE_MEDIA: siteSetting.api.url + "farm/deleteMedia",
  GET_FARM_INFO: siteSetting.api.url + "farm/getFarmDetail",
  VERIFY_USPS_ADDRESS: siteSetting.api.url + "farm/usps/verifyAddress",
  GET_USPS_DOMESTIC_RATE: siteSetting.api.url + "farm/usps/domesticRate",
  GET_USPS_DOMESTIC_PACKAGE_LABEL:
    siteSetting.api.url + "farm/usps/domesticPackageLabel",
  TRACK_USPS_DOMESTIC_PACKAGE:
    siteSetting.api.url + "farm/usps/trackDomesticPackage",
  DELETE_INVENTORY: siteSetting.api.url + "inventory/delete",
  CHANGE_INVENTORY_STATUS: siteSetting.api.url + "inventory/changeStatusInv",
  ADD_DISTRIBUTION: siteSetting.api.url + "distribution/create",
  ADD_ACCOUNT: siteSetting.api.url + "user/addBank",
  EDIT_DISTRIBUTION: siteSetting.api.url + "distribution/update",
  GET_DISTRIBUTION: siteSetting.api.url + "distribution/getFarmerDistribution",
  GET_DISTRIBUTION_BY_ID:
    siteSetting.api.url + "distribution/getFarmerDistributionDetails",
  DELETE_DISTRIBUTION: siteSetting.api.url + "distribution/delete",
  GET_MARKET_LIST: siteSetting.api.url + "market/getMarkets",
  GET_ORDER_LIST: siteSetting.api.url + "payment/getFarmerOrder",
  GET_ORDERS_BY_FARMER: siteSetting.api.url + "payment/orders",
  GET_ALL_ORDER_EXCEL_LIST: siteSetting.api.url + "payment/getAllOrderExcel",
  GET_ORDER_EXCEL_LIST: siteSetting.api.url + "payment/getFarmerOrderExcel",
  VIEW_ORDER_LIST: siteSetting.api.url + "payment/viewOrderById",
  CHANGE_HARVEST_STATUS: siteSetting.api.url + "user/changeHarvestStatus",
  CHANGE_HARVEST_STATUS_BY_FARMER:
    siteSetting.api.url + "user/changeHarvestStatusByFarmer",
  CHANGE_ORDER_STATUS: siteSetting.api.url + "user/changeHarvestStatus",
  CHANGE_ORDER_STATUS_BY_FARMER:
    siteSetting.api.url + "user/changeHarvestStatusByFarmer",
  CHANGE_ORDER_ITEM_STATUS: siteSetting.api.url + "user/changeOrderItemStatus",
  GET_BANK_DETAILS: siteSetting.api.url + "user/getAccountInfo",
}
