import "jspdf-autotable"

import {
  Form,
  Badge,
  Button,
  Col,
  Modal,
  Row,
  DropdownButton,
  Dropdown,
  ButtonGroup,
} from "react-bootstrap"
import React, { Component } from "react"
import { farmActions, orderActions, usersActions } from "../../_actions"
import { ORDER_HARVEST_STATUS } from "../../_constants/order.constants.js"

import { Link } from "react-router-dom"
import Pagination from "react-js-pagination"
import Sidebar from "../../components/sidebar"
import { HarvestList } from "../../components/harvest-list"
import _ from "lodash"
import { connect } from "react-redux"
import moment from "moment-timezone"
import { obj2queryString, queryString2Obj } from "../../util/query-string"
import { SubscriptionInfo } from "../../util/inventory"
import {
  orderShippedBuyList,
  orderNonShippedBuyList,
  orderDistributions,
  orderDetailsTable,
  orderTimeString,
} from "../../util/order"
import USPSBoxForm from "../../components/uspsBoxForm"

const debug = require("debug")("order")

const HARVEST_STATUSES = [
  "New Order",
  "In Harvest List",
  "Fulfilled",
  "Out of Stock",
]

const DEFAULT_ORDER_PAGINATION_COUNT = 10

const sumOrderAndDelivery = (productlist, user) => {
  if (productlist && productlist.length > 0) {
    let countPrice = 0
    for (let i = 0; i < productlist.length; ++i) {
      if (productlist[i].farmUserId === localStorage.getItem("userId")) {
        let num = productlist[i].totalPayPrice
        countPrice = num.toFixed(2)
      }
    }
    return countPrice
  }
}

class Order extends Component {
  constructor(props) {
    super(props)

    let filterHarvest = ORDER_HARVEST_STATUS.ALL

    const params = queryString2Obj({ queryString: window.location.search })
    if (
      params &&
      Object.values(ORDER_HARVEST_STATUS).includes(params.filterHarvest)
    ) {
      filterHarvest = params.filterHarvest
    }

    this.state = {
      orderInfo: [],
      ItemList: [],
      reId: [],
      excelFile: "",
      ItemId: [],
      itemId: [],
      multiValue: [],
      multiItemValue: [],
      reasonErMsg: "",
      show: false,
      farmName: "",
      farmId: "",
      farmStripeId: "",
      showInvoice: false,
      messagePopup: 0,
      alertPopup: 0,
      emailPopup: 0,
      lat: 0,
      long: "",
      count: DEFAULT_ORDER_PAGINATION_COUNT,
      totalCount: 0,
      dataCount: 0,
      filterHarvest,
      pageNo: 1,
      orderInfo1: [],
      profilePicPreview: "",
      userId: "",
      hasOrderInfo: false,

      // USPS Boxes
      showUSPSModal: false,
      boxList: [
        {
          boxLength: "",
          boxWidth: "",
          boxHeight: "",
          boxPounds: "",
          boxOunces: "",
        },
      ],
      boxAddress: {
        from: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          postalCode: "",
        },
        to: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          postalCode: "",
        },
      },
      boxAddressError: [],
      boxFormError: [],
      uspsBoxRates: [],
      uspsLabelOrderInfo: [],
      uspsBoxTotals: 0,
      uspsBoxRateConfirm: false,

      // USPS Address
      uspsAddressLine1: "",
      uspsAddressLine2: "",
      uspsState: "",
      uspsCity: "",
      uspsPostalCode: "",
      uspsErrors: [],
      showUSPSAddressModal: false,

      // USPS Label
      showUSPSLabelModal: false,
      uspsLabelsPrinted: false,
      uspsCreateLabelError: false,
    }
  }

  componentDidMount() {
    this.getUserInfo()
  }

  getFarmDetails = () => {
    let { userId } = this.state

    if (!userId) {
      return
    }

    let self = this
    this.setState({ loader: true, errors: "" })

    let params = `?userId=${userId}&farmerId=${userId}`

    let { dispatch } = this.props
    dispatch(farmActions.getFarmDetails(params)).then((data) => {
      if (!_.isEmpty(data)) {
        let farmInfo = data.responseData.result
        self.setState({ loader: false })

        if (data.statusCode === 1) {
          self.setState({
            farmLogo: farmInfo.length > 0 ? farmInfo[0].farmLogo : "",
            farmName: farmInfo.length > 0 ? farmInfo[0].farmName : "",
            farmId: farmInfo.length > 0 ? farmInfo[0]["_id"] : "",
            farmStripeId: farmInfo.length > 0 ? farmInfo[0].accountId : "",
            addressLine1:
              farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
                ? farmInfo[0].farmAddress[0].addressLine1
                : "",
            addressLine2:
              farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
                ? farmInfo[0].farmAddress[0].addressLine2
                : "",
            city:
              farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
                ? farmInfo[0].farmAddress[0].city
                : "",
            state:
              farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
                ? farmInfo[0].farmAddress[0].state
                : "",
            postalCode:
              farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
                ? farmInfo[0].farmAddress[0].postalCode
                : "",
            boxAddress: {
              from: {
                addressLine1:
                  farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
                    ? farmInfo[0].farmAddress[0].addressLine1
                    : "",
                addressLine2:
                  farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
                    ? farmInfo[0].farmAddress[0].addressLine2
                    : "",
                city:
                  farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
                    ? farmInfo[0].farmAddress[0].city
                    : "",
                state:
                  farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
                    ? farmInfo[0].farmAddress[0].state
                    : "",
                postalCode:
                  farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
                    ? farmInfo[0].farmAddress[0].postalCode
                    : "",
              },
            },
          })
        } else {
          self.setState({ errors: data.error.responseMessage })
        }
      }
    })
  }

  getOrderExcel = (filterHarvest) => {
    let self = this

    // when the list reflects the harvest status, we can
    // just lean on that. but since the list status can be
    // different than what the button offers, we must pass
    // in a value
    let { userId } = this.state
    const queryString = obj2queryString({
      userId,
      filterHarvest,
    })

    let params = `?${queryString}`

    let { dispatch } = this.props
    dispatch(orderActions.getOrderExcel(params)).then((data) => {
      let inventoryData = data

      self.setState({ loader: false })

      if (inventoryData.statusCode === 1) {
        window.open(data.responseData.file)
      } else {
        this.setState({ errors: inventoryData.error.responseMessage })
      }
    })
  }

  // getAllOrderExcel = () => {
  //   let self = this;

  //   let { userId } = this.state;
  //   let params = `?userId=${userId}`;

  //   let { dispatch } = this.props;
  //   dispatch(orderActions.getAllOrderExcel(params)).then((data) => {
  //     let inventoryData = data;

  //     self.setState({ loader: false });

  //     if (inventoryData.statusCode === 1) {
  //       window.open(data.responseData.file);
  //     } else {
  //       this.setState({ errors: inventoryData.error.responseMessage });
  //     }
  //   });
  // };

  sumOrder = (product) => {
    if (!_.isEmpty(product)) {
      let countPrice = 0
      for (let i = 0; i < product.length; i++) {
        if (product[i].farmUserId === localStorage.getItem("userId")) {
          countPrice = product[i].totalPrice
        }
      }
      let price = countPrice.toFixed(2)
      return price
    }
  }

  sumDelivery = (product) => {
    if (!_.isEmpty(product)) {
      let countPrice = 0
      for (let i = 0; i < product.length; i++) {
        if (product[i].farmUserId === localStorage.getItem("userId")) {
          countPrice = product[i].deliveryFees
        }
      }

      return countPrice
    }
  }

  getUserInfo = () => {
    this.setState({ errors: "", redirect: false, loader: false })

    let { dispatch } = this.props
    dispatch(usersActions.getUserInfo()).then((data) => {
      this.setState({ loader: false })

      if (data.statusCode === 1) {
        let userInfo = data.responseData.userProfile
        localStorage.setItem("userId", userInfo._id)

        this.setState({
          userId: userInfo._id,
          userInfo: userInfo,
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          phone: userInfo.phone,
          email: userInfo.email,
          address: userInfo.address,
          addressLine1:
            userInfo.address.length > 0
              ? userInfo.address[0].addressLine1
                ? userInfo.address[0].addressLine1
                : ""
              : "",
          addressLine2:
            userInfo.address.length > 0
              ? userInfo.address[0].addressLine2
                ? userInfo.address[0].addressLine2
                : ""
              : "",
          state:
            userInfo.address.length > 0
              ? userInfo.address[0].state
                ? userInfo.address[0].state
                : ""
              : "",
          postalCode:
            userInfo.address.length > 0
              ? userInfo.address[0].postalCode
                ? userInfo.address[0].postalCode
                : ""
              : "",
          city:
            userInfo.address.length > 0
              ? userInfo.address[0].city
                ? userInfo.address[0].city
                : ""
              : "",
          addressId:
            userInfo.address.length > 0
              ? userInfo.address[0]._id
                ? userInfo.address[0]._id
                : ""
              : "",
          minimumOrderValue: userInfo.minimumOrderValue,
          profilePic: userInfo.profileImage,
          profilePicPreview: userInfo.profileImage,
          certificate: userInfo.certificateImage,
          certificatePreview: userInfo.certificateImage,
          insuranceDoc: userInfo.insuranceImage,
          insurancePreview: userInfo.insuranceImage,
          radius: userInfo.radius,
          emailPopup: userInfo.notificationEmail,
          messagePopup: userInfo.notificationTextMassage,
          alertPopup: userInfo.notificationAppAlert,
          lat: userInfo.geoLocation[0],
          long: userInfo.geoLocation[1],
        })

        this.getOrderList()
        this.getFarmDetails()
      } else {
        this.setState({ errors: data.error.responseMessage })
      }
    })
  }

  handlePageChange = (pageNo) => {
    var self = this
    this.setState({ loader: true, pageNo: pageNo, count: 10 }, () => {
      self.getOrderList()
    })
  }

  getOrderList = () => {
    let { search, userId, filterHarvest, pageNo, count } = this.state

    if (!userId) {
      return
    }

    this.setState({ redirect: false, loader: true, errors: "" })
    const queryString = obj2queryString({
      search,
      userId,
      count,
      pageNo: Math.max(pageNo - 1, 0), // pagination on the server is 0 index, here on the pagination indicator it is 1 index
      filterHarvest,
    })
    let params = `?${queryString}&search=`

    let { dispatch } = this.props
    dispatch(orderActions.getOrderListByFarmer(params)).then((data) => {
      this.setState({
        loader: false,
        hasOrderInfo: true,
      })

      if (data.statusCode === 1) {
        const { responseData } = data
        const { orderList, count, total } = responseData
        this.setState({
          orderInfo: orderList,
          totalCount: total,
          dataCount: count,
        })

        // const hiddenLoadings = document.querySelectorAll('.hidden-loading');
        // hiddenLoadings.forEach(hiddenLoading => {
        //   hiddenLoading.classList.remove('hidden-loading');
        // });
      } else {
        this.setState({ errors: data.error.responseMessage })
      }
    })
  }

  handleFilter = (filterHarvest) => {
    let count = DEFAULT_ORDER_PAGINATION_COUNT
    // the harvest list should not be paginated
    if (filterHarvest === ORDER_HARVEST_STATUS.HARVEST) {
      count = undefined
    }

    this.setState(
      {
        loader: true,
        reasonErMsg: "",
        filterHarvest,
        count,
        pageNo: 1,
      },
      () => this.getOrderList()
    )
  }

  handleClose = () => {
    this.setState({ show: false, showInvoice: false })
  }

  ConvertDate = (created) => {
    const created_date = new Date(created)

    let data = moment(created_date)
      .tz("America/New_York")
      .format("dddd MMMM Do YYYY, h:mm:ss a")

    return data
  }

  ChangeHarvestStatus = (id, harvestStatus) => {
    const { userId } = this.state
    let obj = {
      orderId: id,
      harvestStatus,
      userId,
    }

    let { dispatch } = this.props
    this.setState({ loader: true, show: false })

    dispatch(orderActions.changeHarvestStatusByFarmer(obj)).then((data) => {
      if (data.statusCode === 1) {
        this.getOrderList()
      } else {
        this.setState({ loader: false })
      }
    })
  }

  handleShow = (item) => {
    this.setState({ show: true, ItemList: item })
  }

  OrderBuyMp = (ItemList, itemColl, includeStatus = true) => {
    return (
      !_.isEmpty(ItemList) &&
      ItemList.map((val, i) => (
        <tr key={`order-buy-mp-${i}`}>
          <td>
            <p>
              {val.productQuantity} {val.productName}
            </p>
            <SubscriptionInfo inventoryItem={val} />
          </td>
          <td>{val.variety ? val.variety : ""}</td>

          <td>
            <span>
              ${val.productPrice} *{" "}
              {val.productQuantity ? val.productQuantity : ""} {val.unit}
            </span>
          </td>
          {includeStatus && (
            <td>
              <span className="inlinetext-align">
                <span className="inabs">
                  <input
                    type="checkbox"
                    indeterminate="true"
                    value={val.productId}
                    onChange={(e) => {
                      this.handleItemMultiSelect(
                        e,
                        val.productId && val.productId,
                        ItemList,
                        itemColl
                      )
                    }}
                  />
                </span>
                <span className="cm-aligntext">
                  {parseInt(val.itemStatus) === 1
                    ? "In Harvest List"
                    : parseInt(val.itemStatus) === 2
                    ? "Fulfilled"
                    : parseInt(val.itemStatus) === 3
                    ? "Out of Stock"
                    : "New Order"}
                </span>
              </span>
            </td>
          )}
          <td>
            {val.image && (
              <img alt="" src={val.image} height="40px" width="40px" />
            )}
          </td>
        </tr>
      ))
    )
  }

  OrderMp = (ItemList) => {
    return (
      !_.isEmpty(ItemList.buyList) &&
      ItemList.buyList
        .filter((val) => val.farmUserId === localStorage.getItem("userId"))
        .map((val, i) => {
          return <>{this.OrderBuyMp(val.productList, ItemList, false)}</>
        })
    )
  }

  productName1 = (productName) => {
    return (
      !_.isEmpty(productName) &&
      productName.map((val, i) => (
        <>
          {val.productName} {val.variety ? "," + val.variety : ""} <br />
        </>
      ))
    )
  }

  productQuantity1 = (productName) => {
    return (
      !_.isEmpty(productName) &&
      productName.map((val, i) => (
        <>
          {val.productQuantity
            ? val.productQuantity + " " + (val.unit ? val.unit : "")
            : ""}
          <br />
        </>
      ))
    )
  }

  OrderHarvest1 = (ItemList) => {
    let Json =
      !_.isEmpty(ItemList.buyList) &&
      ItemList.buyList
        .filter((val) => val.farmUserId === this.state.userId)
        .map((val, i) => (
          <>
            {this.productName1(val.productList)} <br />
          </>
        ))

    return Json
  }

  OrderHarvest2 = (ItemList) => {
    return (
      !_.isEmpty(ItemList.buyList) &&
      ItemList.buyList
        .filter((val) => val.farmUserId === this.state.userId)
        .map((val, i) => (
          <>
            {this.productQuantity1(val.productList)} <br />
          </>
        ))
    )
  }

  OrderHarvest3 = (ItemList) => {
    return (
      !_.isEmpty(ItemList.buyList) &&
      ItemList.buyList
        .filter((val) => val.farmUserId === this.state.userId)
        .map((val, i) => (
          <>
            $ {val.totalPrice}
            <br />
          </>
        ))
    )
  }

  handleValidate = () => {
    let validate = true

    if (
      this.state.multiValue === "" ||
      this.state.multiValue.length < 0 ||
      this.state.multiValue.length === 0 ||
      this.state.multiValue === undefined
    ) {
      validate = false
      this.setState({
        reasonErMsg: "Please select check box.",
      })
    } else {
      this.setState({
        reasonErMsg: "",
      })
    }

    return validate
  }

  handleMultiSelect = (e, val) => {
    const { checked } = e.target
    let { multiValue } = this.state

    this.setState({ reasonErMsg: "" })

    multiValue =
      typeof this.state.multiValue === "object" ? this.state.multiValue : []

    if (this.state.reId.includes(val)) {
      for (var i = 0; i < this.state.reId.length; i++) {
        if (this.state.reId[i] === val) {
          this.state.reId.splice(i, 1)
        }
      }
    } else {
      this.state.reId.push(val)
    }

    if (checked && !_.includes(multiValue, val)) {
      multiValue.push(val)
    }
    if (!checked) {
      _.pull(multiValue, val)
    }

    this.setState({
      multiValue,
      // When the multiValue changes, reset the USPS states
      boxList: [
        {
          boxLength: "",
          boxWidth: "",
          boxHeight: "",
          boxPounds: "",
          boxOunces: "",
        },
      ],
      uspsBoxRates: [],
      boxFormError: [],
      uspsBoxTotals: 0,
      uspsLabelOrderInfo: [],
      uspsBoxRateConfirm: false,
    })
  }

  handleAction = (e) => {
    const { userId } = this.state
    let unique = [...new Set(this.state.multiValue)]
    let params = {
      orderId: unique.toString(),
      harvestStatus: e,
      userId,
    }

    if (this.handleValidate()) {
      let { dispatch } = this.props
      this.setState({ loader: true })

      dispatch(orderActions.changeOrderStatusByFarmer(params)).then((data) => {
        if (data.statusCode === 1) {
          this.setState({ multiValue: [] })
          this.getOrderList()
        } else {
          this.setState({
            loader: false,
            reId: [],
            multiValue: [],
            reasonErMsg: "",
            errors: data.error.responseMessage,
          })
        }
      })
    }
  }

  base64ToPdf(base64Binary, ignoreDisplay = false) {
    const decodedData = Buffer.from(base64Binary, "base64")
    const blob = new Blob([decodedData], {
      type: "application/pdf",
    })

    const url = URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.href = url

    const dateNow = moment().format("MM-DD-YYYY-hh:mm:ss")

    a.download = `usps-label-${dateNow}.pdf`
    a.click()

    if (!ignoreDisplay) {
      const iframe = document.createElement("iframe")
      const newDiv = document.createElement("div")
      newDiv.id = "usps-shipping-labels"
      document.getElementById("usps_labels").appendChild(newDiv)

      iframe.src = url
      iframe.style.width = "100%"
      iframe.style.height = "100%"

      // Append to usps-shipping-labels id
      document.getElementById("usps-shipping-labels").appendChild(iframe)
    }
  }

  handleUSPSAction = (e) => {
    const {
      orderInfo,
      multiValue,
      addressLine1,
      addressLine2,
      city,
      state,
      postalCode,
      boxAddress,
    } = this.state

    const checkedOrders = orderInfo.filter((order) =>
      multiValue.includes(order._id)
    )

    // Set boxAddress.to values once an order has been clicked
    // split the checkedOrders[0].location into an array
    const locationArray = checkedOrders[0].location.split(",")
    // If locationArray.length is less than 5, that means we don't have an addressLine2
    // So we need to add an empty string to the array
    if (locationArray.length < 5) {
      locationArray.splice(1, 0, "")
    }

    this.setState({
      showUSPSAddressModal: true,
      uspsLabelOrderInfo: checkedOrders,

      // Update boxAddress to values based on locationArray
      boxAddress: {
        to: {
          addressLine1: locationArray[0] ? locationArray[0].trim() : "",
          addressLine2: locationArray[1] ? locationArray[1].trim() : "",
          city: locationArray[2] ? locationArray[2].trim() : "",
          state: locationArray[3] ? locationArray[3].trim() : "",
          postalCode: locationArray[4] ? locationArray[4].trim() : "",
        },
        // Keep "from" the same
        from: {
          ...boxAddress.from,
        },
      },

      // Prepopulate the address fields with the farm's address
      uspsAddressLine1: addressLine1,
      uspsAddressLine2: addressLine2,
      uspsCity: city,
      uspsState: state,
      uspsPostalCode: postalCode,
    })
  }

  handleUSPSAddressValidate = () => {
    let validate = false
    let uspsErrors = {}
    let { uspsAddressLine1, uspsCity, uspsState, uspsPostalCode } = this.state

    if (_.isEmpty(uspsAddressLine1)) {
      validate = true
      uspsErrors["uspsAddressLine1"] = "Address is required."
    }
    if (_.isEmpty(uspsCity)) {
      validate = true
      uspsErrors["uspsCity"] = "City is required."
    }
    if (_.isEmpty(uspsState)) {
      validate = true
      uspsErrors["uspsState"] = "State is required."
    }
    if (_.isEmpty(uspsPostalCode)) {
      validate = true
      uspsErrors["uspsPostalCode"] = "Postal code is required."
    }
    this.setState({ uspsErrors })
    return validate
  }

  handleVerifyAddress = () => {
    let {
      uspsAddressLine1,
      uspsAddressLine2,
      uspsCity,
      uspsState,
      uspsPostalCode,
    } = this.state

    let params = {
      address1: uspsAddressLine1,
      address2: uspsAddressLine2,
      city: uspsCity,
      state: uspsState,
      zip5: uspsPostalCode,
    }

    let { dispatch } = this.props
    let self = this

    dispatch(farmActions.verifyAddress(params)).then((data) => {
      if (data.statusCode === 1) {
        self.setState({
          loader: false,
          reasonErMsg: "",
        })
        // this.notify("success", "Address verified successfully.")

        this.setState({
          showUSPSAddressModal: false,
          showUSPSModal: true,
        })
      } else {
        self.setState({ loader: false })
        this.setState({
          reasonErMsg: "",
          uspsErrors: data.error.responseMessage,
        })
      }
    })
  }

  handleUSPSAddressSubmit = (e) => {
    e.preventDefault()
    this.handleUSPSAddressValidate()
    if (!this.handleUSPSAddressValidate()) {
      this.handleVerifyAddress()
    }
  }

  handleUSPSValidate = () => {
    const { boxList, boxAddress } = this.state
    let errors = []
    let zipValidate = false
    let boxAddressErrors = {
      from: {},
      to: {},
    }

    boxList.map((box, i) => {
      const combinedWeight =
        parseFloat(box.boxPounds * 16) + parseFloat(box.boxOunces)

      if (
        _.isEmpty(box.boxLength) ||
        _.isEmpty(box.boxWidth) ||
        _.isEmpty(box.boxHeight) ||
        _.isEmpty(box.boxPounds) ||
        _.isEmpty(box.boxOunces)
      ) {
        errors.push({
          boxLength: _.isEmpty(box.boxLength) ? "Box length is required." : "",
          boxWidth: _.isEmpty(box.boxWidth) ? "Box width is required." : "",
          boxHeight: _.isEmpty(box.boxHeight) ? "Box height is required." : "",
          boxPounds: _.isEmpty(box.boxPounds) ? "Box pounds is required." : "",
          boxOunces: _.isEmpty(box.boxOunces) ? "Box ounces is required." : "",
        })
      } else if (combinedWeight > 70 * 16) {
        // 70 pounds * 16 ounces = 1120 ounces
        // USPS only allows a maximum of 70 pounds or 1120 ounces
        errors.push({
          boxPounds: "Combined weight cannot exceed 70 pounds.",
          boxOunces: "Combined weight cannot exceed 70 pounds.",
        })
      } else {
        // Push an empty object to the errors array
        errors.push({})
      }
    })

    Object.keys(boxAddress).forEach((value, firstIndex) => {
      Object.values(boxAddress[value]).forEach((value, index) => {
        // See if were are in the from or to object
        const type = Object.keys(boxAddress)[firstIndex]

        // Get the value that is empty
        const emptyValue = Object.keys(boxAddress[type])[index]

        // Edit the emptyValue so that it is readable
        const readableEmptyValue = emptyValue
          .split(/(?=[A-Z]+|[0-9])/)
          // Uppercase the first letter of each word
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")

        // If the value is empty and not the address line 2
        if (_.isEmpty(value) && emptyValue !== "addressLine2") {
          zipValidate = true
          boxAddressErrors[type][
            emptyValue
          ] = `${readableEmptyValue} is required.`
        }
      })
    })

    // Check if the errors array contains any empty object
    let isFormValid = errors.some((error) => {
      return Object.keys(error).length !== 0
    })

    let isBoxAddressFormValid =
      Object.keys(boxAddressErrors.from).length !== 0 ||
      Object.keys(boxAddressErrors.to).length !== 0

    this.setState({
      boxFormError: errors,
      boxAddressError: boxAddressErrors,
    })

    // Set the state
    if (isFormValid || isBoxAddressFormValid) {
      return true
    }

    return false
  }

  handleUSPSSubmit = (e) => {
    e.preventDefault()
    this.handleUSPSValidate()
    if (!this.handleUSPSValidate()) {
      this.handleGetUSPSRate()
    }
  }

  handleAddBox = () => {
    const { boxList } = this.state

    // Add a new box to the boxList
    boxList.push({
      boxLength: "",
      boxWidth: "",
      boxHeight: "",
      boxPounds: "",
      boxOunces: "",
    })

    this.setState({
      boxList,
      uspsBoxRateConfirm: false,
    })
  }

  handleRemoveBox = (index) => {
    const { boxList, uspsBoxTotals, uspsBoxRates } = this.state

    // Remove the box from the boxList based on the index
    boxList.splice(index, 1)

    let updatedBoxTotals = parseFloat(uspsBoxTotals)

    if (uspsBoxRates.length > 0 && uspsBoxRates[index] !== undefined) {
      // Reduce the uspsBoxTotals by the box rate for the given index
      // Get the "CommercialRate" instead of the "Rate"
      updatedBoxTotals -= parseFloat(uspsBoxRates[index].Postage.CommercialRate)

      // Remove the box rate from the uspsBoxRates array based on the index
      uspsBoxRates.splice(index, 1)
    }

    this.setState({
      boxList,
      uspsBoxRates,
      uspsBoxTotals:
        updatedBoxTotals <= 0 ? 0 : parseFloat(updatedBoxTotals).toFixed(2),
    })
  }

  handleBoxInputChange = (e, index) => {
    const { boxList } = this.state

    // Update the boxList based on the index
    boxList[index][e.target.name] = e.target.value

    this.setState({
      boxList,
      uspsBoxRateConfirm: false,
    })
  }

  handleVerifyUSPSAddressChanged = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleShippingAddressChanged = (e) => {
    const { boxAddress } = this.state

    // Split the name and get the index
    let [index, value] = e.target.name.split(".")

    // Update the boxAddress based on the name and if it's "from" or "to"
    boxAddress[index][value] = e.target.value

    this.setState({
      uspsBoxRateConfirm: false,
      boxAddress,
    })
  }

  handleGetUSPSRate = () => {
    const { boxList, boxAddress } = this.state
    let { dispatch } = this.props
    let self = this

    let params = {
      boxes: boxList,
      postalCodeOrigin: boxAddress.from.postalCode,
      postalCodeDestination: boxAddress.to.postalCode,
    }

    dispatch(farmActions.domesticRate(params)).then((data) => {
      if (data.statusCode === 1) {
        const packageRates = data.responseData.RateV4Response.Package

        const packageRatesArray =
          Array.isArray(packageRates) === true ? packageRates : [packageRates]

        // Get the "CommercialRate" instead of the "Rate"
        const packageRateTotals = packageRatesArray.reduce(
          (acc, curr) => acc + parseFloat(curr.Postage.CommercialRate),
          0
        )

        self.setState({
          loader: false,
          reasonErMsg: "",
          uspsBoxRates: packageRatesArray,
          uspsBoxTotals: packageRateTotals.toFixed(2),
          uspsBoxRateConfirm: true,
        })
        // this.notify("success", "Address verified successfully.")
      } else {
        self.setState({ loader: false })
        this.setState({
          reasonErMsg: "",
          uspsErrors: data.error.responseMessage,
        })
      }
    })
  }

  handleShowUSPSConfirmModal = () => {
    this.setState({
      showUSPSModal: false,
      showUSPSLabelModal: true,
    })
  }

  handleCreateLabels = () => {
    const { boxList, boxAddress, uspsLabelOrderInfo } = this.state

    let { dispatch } = this.props
    let self = this

    const params = {
      orderNumber: uspsLabelOrderInfo[0]._id,
      farmId: this.state.farmId,
      farmStripeId: this.state.farmStripeId,
      boxes: boxList,
      farm: {
        addressLine1: boxAddress.from.addressLine1.trim(),
        addressLine2: boxAddress.from.addressLine2.trim(),
        city: boxAddress.from.city.trim(),
        state: boxAddress.from.state.trim(),
        postalCode: boxAddress.from.postalCode.trim(),
        phone: this.state.phone,
        name: this.state.farmName,
        email: this.state.email,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
      },
      customer: {
        addressLine1: boxAddress.to.addressLine1.trim(),
        addressLine2: boxAddress.to.addressLine2.trim(),
        city: boxAddress.to.city.trim(),
        state: boxAddress.to.state.trim(),
        postalCode: boxAddress.to.postalCode.trim(),
        phone: uspsLabelOrderInfo[0].billingInfo.phoneNumber,
        name: uspsLabelOrderInfo[0].customer,
        customerRefNo2: uspsLabelOrderInfo[0].customerId,
      },
    }

    dispatch(farmActions.domesticPackageLabel(params)).then((data) => {
      if (data.statusCode === 1) {
        this.setState({
          uspsLabelsPrinted: true,
        })

        data.responseData.map((label) => {
          this.base64ToPdf(label.eVSResponse.LabelImage)
        })
        // this.notify("success", data.responseData.message)
      } else {
        self.setState({ loader: false })
        this.setState({
          reasonErMsg: "",
          errors: data.error.responseMessage,
        })

        if (data.error.errors) {
          let uspsErrors = {}
          uspsErrors["labelError"] =
            "An error occured while creating the label. Please ensure the Ship From and Ship To values are correct."
          // Turn the "Create Labels" button into a "Back" button and show the errors
          this.setState({
            uspsCreateLabelError: true,
            uspsErrors,
          })
        }
      }
    })
  }

  handleLabelFinish = () => {
    // Reset prices, boxes, and errors
    // after a label is printed
    this.setState({
      multiValue: [],
      uspsLabelsPrinted: false,

      // USPS Boxes
      boxList: [],
      boxAddressError: [],
      boxFormError: [],
      uspsBoxRates: [],
      uspsBoxTotals: 0,

      // USPS Label
      showUSPSLabelModal: false,
    })

    // Update the order list
    // displaying the new label
    this.getOrderList()
  }

  handleItemMultiSelect = (e, val, ItemList, io) => {
    let { multiItemValue } = this.state
    this.setState({ reasonErMsg: "" })
    multiItemValue = []

    if (this.state.itemId !== undefined && this.state.itemId.includes(val)) {
      for (var i = 0; i < this.state.itemId.length; i++) {
        if (this.state.itemId[i] === val) {
          this.state.itemId.splice(i, 1)
        }
      }
    } else {
      this.state.itemId.push(val)
    }

    ItemList.map((value) => {
      if (value.productId === val) {
        if (value.itemStatus === 2) {
          multiItemValue.push(value._id)
        } else if (value.itemStatus === 3) {
          multiItemValue.splice(value._id)
        } else if (value.itemStatus === 0) {
          multiItemValue.push(value._id)
        }
      }
    })

    this.setState({ multiItemValue: [...this.state.multiItemValue, val] })
  }

  handleItemAction = (id, harvestStatus) => {
    let unique = [...new Set(this.state.multiItemValue)]

    let params = {
      orderId: id,
      itemId: unique,
      itemStatus: harvestStatus,
    }

    let self = this

    let { dispatch } = this.props
    self.setState({ loader: true, show: false })
    dispatch(orderActions.changeOrderItemStatus(params)).then((data) => {
      if (data.statusCode === 1) {
        self.setState({
          loader: false,
          itemId: [],
          multiValue: "",
          reasonErMsg: "",
        })
        this.notify("success", data.responseData.message)
        this.getOrderList()
      } else {
        self.setState({ loader: false })
        this.setState({
          itemId: [],
          multiValue: "",
          reasonErMsg: "",
          errors: data.error.responseMessage,
        })
      }
    })
  }

  renderOrderStatus = (status) => {
    const badgeConfig = {
      "New Order": "success",
      Fulfilled: "info",
      "In Harvest List": "warning",
    }

    const displayStatus = HARVEST_STATUSES[status]

    return <Badge variant={badgeConfig[displayStatus]}>{displayStatus}</Badge>
  }

  render() {
    let {
      filterHarvest,
      reasonErMsg,
      orderInfo,
      count,
      pageNo,
      ItemList,
      userId,
      farmStripeId,
      uspsLabelOrderInfo,
      uspsErrors,
      boxList,
      boxFormError,
      uspsBoxRates,
      uspsBoxTotals,
      uspsBoxRateConfirm,
      boxAddressError,
      uspsLabelsPrinted,
      uspsCreateLabelError,
    } = this.state

    return (
      <>
        <Sidebar />
        <div className="body_container">
          <div className="body_inner_wrapper">
            {this.state.loader ? (
              <div className="loader_wrapper">
                <div className="spinner-border text-info" role="status"></div>
              </div>
            ) : (
              <>
                <header className="header_content orders-header">
                  <h1 className="orders-header-heading h6">
                    {/* show heading ass appropriate */}
                    {filterHarvest === ORDER_HARVEST_STATUS.ALL && "All Orders"}
                    {filterHarvest === ORDER_HARVEST_STATUS.HARVEST &&
                      "Harvest List"}
                    {filterHarvest === ORDER_HARVEST_STATUS.FULFILLED &&
                      "Fulfilled Orders"}
                  </h1>
                  <div className="d-flex text-nowrap header_btn-broken orders-header-buttons">
                    <Button
                      variant="primary"
                      disabled={_.isEmpty(this.state.orderInfo) ? true : false}
                      onClick={() =>
                        this.handleAction(ORDER_HARVEST_STATUS.HARVEST)
                      }
                    >
                      {" "}
                      Add to Harvest List
                    </Button>
                    <Button
                      variant="secondary"
                      disabled={
                        _.isEmpty(this.state.multiValue) ||
                        this.state.multiValue.length > 1
                          ? true
                          : false
                      }
                      onClick={() => this.handleUSPSAction()}
                    >
                      Print USPS Label
                    </Button>
                    <Button
                      variant="info"
                      disabled={_.isEmpty(this.state.orderInfo) ? true : false}
                      onClick={() =>
                        this.handleAction(ORDER_HARVEST_STATUS.FULFILLED)
                      }
                    >
                      {" "}
                      Mark Order Fulfilled
                    </Button>
                    <DropdownButton
                      as={ButtonGroup}
                      id="harvest-list-options"
                      title="View Harvest List"
                      variant="warning"
                    >
                      <Dropdown.Item
                        onClick={() =>
                          this.handleFilter(ORDER_HARVEST_STATUS.HARVEST)
                        }
                      >
                        {" "}
                        In List Below
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={this.getOrderExcel.bind(
                          this,
                          ORDER_HARVEST_STATUS.HARVEST
                        )}
                      >
                        {" "}
                        Export As Excel
                      </Dropdown.Item>
                    </DropdownButton>
                    <Button
                      variant="info"
                      onClick={() =>
                        this.handleFilter(ORDER_HARVEST_STATUS.FULFILLED)
                      }
                    >
                      {" "}
                      View Fulfilled Orders
                    </Button>
                    <Button
                      variant="success"
                      onClick={() =>
                        this.handleFilter(ORDER_HARVEST_STATUS.ALL)
                      }
                    >
                      {" "}
                      View All Orders
                    </Button>
                    <Button
                      onClick={this.getOrderExcel.bind(
                        this,
                        ORDER_HARVEST_STATUS.ALL
                      )}
                      variant="info"
                    >
                      <i className="fas fa-upload"></i> Export All Orders as
                      Excel
                    </Button>
                  </div>
                </header>
                {/* all orders */}
                {(filterHarvest === ORDER_HARVEST_STATUS.ALL ||
                  filterHarvest === "") && (
                  <div className="main_content">
                    <div className="table-responsive">
                      <table className="table cm_bottom_space">
                        <thead>
                          <tr className="border_top_0">
                            <th>Change Status</th>
                            <th scope="col">Placed On</th>
                            <th scope="col" className="ws-nowrap">
                              Customer
                            </th>
                            <th scope="col" className="ws-nowrap">
                              {" "}
                              Total
                            </th>
                            <th scope="col" className="ws-nowrap">
                              Distributions
                            </th>
                            <th scope="col" className="ws-nowrap">
                              Day & Time
                            </th>
                            <th scope="col" className="ws-nowrap">
                              Status
                            </th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!_.isEmpty(orderInfo) &&
                            orderInfo &&
                            orderInfo.map((item, i) => (
                              <tr key={item._id}>
                                <td>
                                  <div
                                    className={`cm_radio_3_wrapper`}
                                    style={{ background: "#fff" }}
                                  >
                                    <input
                                      type="checkbox"
                                      indeterminate="true"
                                      value={item.status}
                                      onChange={(e) =>
                                        this.handleMultiSelect(
                                          e,
                                          item._id && item._id
                                        )
                                      }
                                    />
                                    <span className="cm_radio_3"></span>
                                  </div>
                                </td>
                                <td>{item.placedOn}</td>
                                <td>{item.customer}</td>
                                <td>{item.total}</td>
                                <td>
                                  {orderDistributions({
                                    order: item,
                                    farmUserId: userId,
                                  }).map((distributionString) => {
                                    return (
                                      <p className="mb0">
                                        {distributionString}
                                      </p>
                                    )
                                  })}
                                </td>
                                <td>
                                  {orderTimeString({
                                    order: item,
                                    farmUserId: userId,
                                  })}
                                </td>
                                <td>{this.renderOrderStatus(item.status)}</td>
                                <td>
                                  <div className="action_wrapper ws-nowrap">
                                    <span>
                                      {" "}
                                      <i
                                        className="fas fa-eye"
                                        onClick={() => this.handleShow(item)}
                                      ></i>
                                    </span>

                                    <span>
                                      <Link to={`vieworder/${item._id}`}>
                                        <i
                                          className="fas fa-save"
                                          title="Invoice"
                                        ></i>
                                      </Link>
                                    </span>

                                    <span
                                      title="Add to harvest"
                                      onClick={() =>
                                        this.ChangeHarvestStatus(
                                          item._id,
                                          ORDER_HARVEST_STATUS.HARVEST
                                        )
                                      }
                                      className="icon-sickle"
                                    ></span>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {this.state.totalCount > this.state.count && (
                        <div className="d-flex justify-content-end cm-pagination pagination-all-orders">
                          <Pagination
                            itemClassPrev={"prevClass"}
                            itemClassNext={"nextClass"}
                            activePage={this.state.pageNo}
                            itemsCountPerPage={this.state.count}
                            totalItemsCount={this.state.totalCount}
                            pageRangeDisplayed={3}
                            onChange={this.handlePageChange}
                          />
                        </div>
                      )}
                      {_.isEmpty(this.state.orderInfo) &&
                        this.state.hasOrderInfo && (
                          <h3 className="text-center">No data found</h3>
                        )}
                      {reasonErMsg && (
                        <div className="text-center error ">{reasonErMsg}</div>
                      )}
                    </div>
                  </div>
                )}
                {/* harvest list */}
                {/* setup harvest list as a new component, so it could also be used within the admin, if needed */}
                {filterHarvest === ORDER_HARVEST_STATUS.HARVEST && (
                  <div className="main_content">
                    <HarvestList
                      orderInfo={orderInfo}
                      farmUserId={localStorage.getItem("userId")}
                    />
                  </div>
                )}
                {/* fulfilled orders */}
                {filterHarvest === ORDER_HARVEST_STATUS.FULFILLED && (
                  <div className="main_content">
                    <div className="table-responsive">
                      <table className="table cm_bottom_space">
                        <thead>
                          <tr className="broder_top_0">
                            <th>Change Status</th>
                            <th scope="col">Placed On</th>
                            <th scope="col" className="ws-nowrap">
                              Customer
                            </th>
                            <th scope="col" className="ws-nowrap">
                              Item
                            </th>
                            <th scope="col" className="ws-nowrap">
                              {" "}
                              Quantity
                            </th>
                            <th scope="col">Price</th>
                            <th scope="col" className="ws-nowrap">
                              Distributions
                            </th>

                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!_.isEmpty(orderInfo) &&
                            orderInfo.map((item, i) => {
                              const harvestStatus = item.buyList[0]
                                .harvestStatus
                                ? +item.buyList[0].harvestStatus
                                : -1
                              return (
                                <tr key={item._id}>
                                  <td>
                                    <div
                                      className={`cm_radio_3_wrapper`}
                                      style={{ background: "#fff" }}
                                    >
                                      <input
                                        type="checkbox"
                                        indeterminate
                                        value={item.status}
                                        onChange={(e) =>
                                          this.handleMultiSelect(
                                            e,
                                            item._id && item._id
                                          )
                                        }
                                      />
                                      <span className="cm_radio_3"></span>
                                    </div>
                                  </td>
                                  <td>{item.placedOn}</td>
                                  <td>{item.customer}</td>
                                  {/*{item}*/}
                                  <td> {this.OrderHarvest1(item)}</td>
                                  {/*{unit}*/}
                                  <td> {this.OrderHarvest2(item)}</td>
                                  {/*{price}*/}
                                  <td> {this.OrderHarvest3(item)}</td>
                                  <td>
                                    {orderDistributions({
                                      order: item,
                                      farmUserId: userId,
                                    }).map((distributionString) => {
                                      return (
                                        <p className="dark m0">
                                          {distributionString}
                                        </p>
                                      )
                                    })}
                                  </td>
                                  <td>
                                    {harvestStatus === 0 && (
                                      <Badge variant="success">New Order</Badge>
                                    )}
                                    {harvestStatus === 1 && (
                                      <Badge variant="warning">
                                        In Harvest List
                                      </Badge>
                                    )}
                                    {harvestStatus === 2 && (
                                      <Badge variant="info">FulFilled</Badge>
                                    )}
                                    {harvestStatus == 3 ? "Out of Stock" : ""}
                                  </td>
                                  <td>
                                    <div className="action_wrapper1 ws-nowrap">
                                      <span
                                        onClick={() => this.handleShow(item)}
                                      >
                                        <i
                                          className="fas fa-eye"
                                          title="Order Details"
                                        ></i>
                                      </span>

                                      <span>
                                        <Link to={`vieworder/${item._id}`}>
                                          <i
                                            className="fas fa-save"
                                            title="Invoicddddde"
                                          ></i>
                                        </Link>
                                      </span>
                                      <span
                                        title="Add to harvest"
                                        onClick={() =>
                                          this.ChangeHarvestStatus(
                                            item._id,
                                            ORDER_HARVEST_STATUS.HARVEST
                                          )
                                        }
                                        className="icon-sickle"
                                      ></span>
                                    </div>
                                  </td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </table>
                      {this.state.totalCount > this.state.count && (
                        <div className="d-flex justify-content-end cm-pagination pagination-fulfilled-orders">
                          <Pagination
                            itemClassPrev={"prevClass"}
                            itemClassNext={"nextClass"}
                            activePage={this.state.pageNo}
                            itemsCountPerPage={this.state.dataCount}
                            totalItemsCount={this.state.totalCount}
                            pageRangeDisplayed={3}
                            onChange={this.handlePageChange}
                          />
                        </div>
                      )}
                      {_.isEmpty(this.state.orderInfo) &&
                        this.state.hasOrderInfo && (
                          <h3 className="text-center">No data found</h3>
                        )}
                      {reasonErMsg && (
                        <div className="text-center error ">{reasonErMsg}</div>
                      )}
                    </div>
                  </div>
                )}{" "}
              </>
            )}
          </div>

          <Modal
            aria-label="example-modal-sizes-title-sm "
            show={this.state.show}
            onHide={this.handleClose}
            className="cm_order_view order-detailsmodal"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">
                <h6>Order Details</h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div id={"divIdToPrint1"} className="">
                <Row>
                  <Col md="12">
                    {ItemList.customer && (
                      <p>
                        Name: <span>{ItemList.customer}</span>
                      </p>
                    )}

                    {ItemList.billingInfo &&
                      ItemList.billingInfo.phoneNumber && (
                        <p>
                          Phone:{" "}
                          <span>{ItemList.billingInfo.phoneNumber} </span>
                        </p>
                      )}

                    {ItemList && ItemList.status !== undefined && (
                      <p>
                        Status: <span>{HARVEST_STATUSES[ItemList.status]}</span>
                      </p>
                    )}

                    {ItemList.billingInfo && ItemList.billingInfo && (
                      <p>
                        Address:{" "}
                        <span>
                          {ItemList.billingInfo.addressLine1}{" "}
                          {ItemList.billingInfo.addressLine2}{" "}
                          {ItemList.billingInfo.city}{" "}
                          {ItemList.billingInfo.state}{" "}
                          {ItemList.billingInfo.country}{" "}
                          {ItemList.billingInfo.postalCode}
                        </span>
                      </p>
                    )}

                    <p>
                      Distributions:
                      {orderDistributions({
                        order: ItemList,
                        farmUserId: userId,
                      }).map((distributionString) => {
                        return <p className="dark m0">{distributionString}</p>
                      })}
                    </p>
                    <p>
                      Farm Name: <span>{this.state.farmName}</span>
                    </p>
                    {ItemList &&
                      ItemList.buyList &&
                      ItemList.buyList.map((val, i) =>
                        val.trackingInfo && val.trackingInfo.length > 0 ? (
                          <div className="usps_border_top">
                            {val.trackingInfo.map((box, index) => (
                              <div className="d-flex align-items-center mb-2">
                                <p className="mb-0 mr-2">
                                  USPS Package {index + 1}:
                                </p>
                                <a
                                  href={`https://tools.usps.com/go/TrackConfirmAction_input?qtc_tLabels1=${box.trackingNumber}`} // Update this for the list
                                  target="_blank"
                                >
                                  <Button
                                    variant="link"
                                    className="usps_order_button mr-2"
                                  >
                                    Track USPS Package
                                  </Button>
                                </a>
                                <Button
                                  onClick={() => {
                                    this.base64ToPdf(box.labelImage, true)
                                  }}
                                  variant="light"
                                  className="usps_order_button"
                                >
                                  Download USPS Label
                                </Button>
                              </div>
                            ))}
                          </div>
                        ) : null
                      )}
                  </Col>
                </Row>
                <div className="order_items">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="ws-nowrap">Product Name</th>
                          <th className="ws-nowrap">Variety</th>
                          <th className="ws-nowrap">Product Price</th>
                          <th className="ws-nowrap">Distribution</th>
                          <th className="ws-nowrap">Product Image</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderDetailsTable({
                          order: ItemList,
                          farmUserId: userId,
                        }).map(
                          ({ tableRowValues, productListEntry }, index) => {
                            return (
                              <tr key={`order-buy-mp-${index}`}>
                                <td>
                                  <p>{tableRowValues.productName}</p>
                                  <SubscriptionInfo
                                    inventoryItem={productListEntry}
                                  />
                                </td>
                                <td>{tableRowValues.variety}</td>
                                <td>
                                  <span>{tableRowValues.price}</span>
                                </td>
                                <td>{tableRowValues.distributionString}</td>
                                <td>
                                  {tableRowValues.image && (
                                    <img
                                      alt=""
                                      src={tableRowValues.image}
                                      height="40px"
                                      width="40px"
                                    />
                                  )}
                                </td>
                              </tr>
                            )
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="">
                  <p>
                    Order Total:
                    <span>${this.sumOrder(ItemList && ItemList.buyList)}</span>
                  </p>

                  <p>
                    Delivery Charges:
                    <span>
                      ${this.sumDelivery(ItemList && ItemList.buyList)}
                    </span>
                  </p>

                  <p>
                    Total:
                    <span>
                      {" "}
                      $
                      {sumOrderAndDelivery(
                        ItemList && ItemList.buyList,
                        this.state.userId
                      )}
                    </span>
                  </p>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="print_button orderabs">
                <Link
                  className="btn btn-light "
                  to={`vieworder/${ItemList._id}`}
                >
                  <i className="fas fa-print" title="Invoicddddde"></i>Print
                </Link>
              </div>
            </Modal.Footer>
          </Modal>

          {/* Verify USPS Address */}
          <Modal
            show={this.state.showUSPSAddressModal}
            onHide={() =>
              this.setState({
                showUSPSAddressModal: false,
              })
            }
            className="cm_order_view dlt_item"
          >
            <form onSubmit={this.handleUSPSAddressSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4>Verify your farm address</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Address 1</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Address 1"
                      onChange={this.handleVerifyUSPSAddressChanged}
                      name="uspsAddressLine1"
                      value={this.state.uspsAddressLine1}
                    />
                    <span className="error_msg">
                      {uspsErrors["uspsAddressLine1"]}
                    </span>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Address 2</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Apt / Unit / Suite / etc. (optional)"
                      onChange={this.handleVerifyUSPSAddressChanged}
                      name="uspsAddressLine2"
                      value={this.state.uspsAddressLine2}
                    />
                    {/* Commented out since the address line 2 is not required, for now */}
                    {/* <span className="error_msg">{uspsErrors["uspsAddressLine2"]}</span> */}
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="City"
                      onChange={this.handleVerifyUSPSAddressChanged}
                      name="uspsCity"
                      value={this.state.uspsCity}
                    />
                    <span className="error_msg">{uspsErrors["uspsCity"]}</span>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Postal Code</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Postal Code"
                      onChange={this.handleVerifyUSPSAddressChanged}
                      name="uspsPostalCode"
                      value={this.state.uspsPostalCode}
                    />
                    <span className="error_msg">
                      {uspsErrors["uspsPostalCode"]}
                    </span>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="State"
                      onChange={this.handleVerifyUSPSAddressChanged}
                      name="uspsState"
                      value={this.state.uspsState}
                    />
                    <span className="error_msg">{uspsErrors["uspsState"]}</span>
                  </Form.Group>
                </Form.Row>
              </Modal.Body>
              <Modal.Footer>
                <div className="footer_btn_wrapper">
                  <Button variant="warning" type="submit">
                    Verify
                  </Button>
                  <Button
                    variant="dark"
                    onClick={() =>
                      this.setState({
                        showUSPSAddressModal: false,
                      })
                    }
                  >
                    Cancel
                  </Button>
                </div>
              </Modal.Footer>
            </form>
          </Modal>

          {/* USPS Modal */}
          <Modal
            show={this.state.showUSPSModal}
            onHide={() =>
              this.setState({
                showUSPSModal: false,
              })
            }
            className="cm_order_view dlt_item dlt_item__usps"
          >
            <form onSubmit={this.handleUSPSSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4>USPS Box Rates</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Row className="usps_border_bottom">
                  <Form.Group as={Col}>
                    <Form.Label className="usps_form_label">
                      Ship From
                    </Form.Label>
                    <Form.Row>
                      <Form.Group as={Col}>
                        <Form.Label>Address 1</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Address 1"
                          onChange={this.handleShippingAddressChanged}
                          name="from.addressLine1"
                          value={
                            this.state.boxAddress.from.addressLine1
                              ? this.state.boxAddress.from.addressLine1
                              : ""
                          }
                        />
                        {boxAddressError &&
                        boxAddressError.from &&
                        boxAddressError.from.addressLine1 ? (
                          <span className="error_msg">
                            {boxAddressError.from.addressLine1}
                          </span>
                        ) : null}
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>Address 2</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Address 2"
                          onChange={this.handleShippingAddressChanged}
                          name="from.addressLine2"
                          value={
                            this.state.boxAddress.from.addressLine2
                              ? this.state.boxAddress.from.addressLine2
                              : ""
                          }
                        />
                        {boxAddressError &&
                        boxAddressError.from &&
                        boxAddressError.from.addressLine2 ? (
                          <span className="error_msg">
                            {boxAddressError.from.addressLine2}
                          </span>
                        ) : null}
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col}>
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="City"
                          onChange={this.handleShippingAddressChanged}
                          name="from.city"
                          value={
                            this.state.boxAddress.from.city
                              ? this.state.boxAddress.from.city
                              : ""
                          }
                        />
                        {boxAddressError &&
                        boxAddressError.from &&
                        boxAddressError.from.city ? (
                          <span className="error_msg">
                            {boxAddressError.from.city}
                          </span>
                        ) : null}
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>State</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="State"
                          onChange={this.handleShippingAddressChanged}
                          name="from.state"
                          value={
                            this.state.boxAddress.from.state
                              ? this.state.boxAddress.from.state
                              : ""
                          }
                        />
                        {boxAddressError &&
                        boxAddressError.from &&
                        boxAddressError.from.state ? (
                          <span className="error_msg">
                            {boxAddressError.from.state}
                          </span>
                        ) : null}
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>Postal Code</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Postal Code"
                          onChange={this.handleShippingAddressChanged}
                          name="from.postalCode"
                          value={
                            this.state.boxAddress.from.postalCode
                              ? this.state.boxAddress.from.postalCode
                              : ""
                          }
                        />
                        {boxAddressError &&
                        boxAddressError.from &&
                        boxAddressError.from.postalCode ? (
                          <span className="error_msg">
                            {boxAddressError.from.postalCode}
                          </span>
                        ) : null}
                      </Form.Group>
                    </Form.Row>
                  </Form.Group>
                </Form.Row>

                <Form.Row className="usps_border_bottom">
                  <Form.Group as={Col}>
                    <Form.Label className="usps_form_label">Ship To</Form.Label>
                    <Form.Row>
                      <Form.Group as={Col}>
                        <Form.Label>Address 1</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Address 1"
                          onChange={this.handleShippingAddressChanged}
                          name="to.addressLine1"
                          value={
                            this.state.boxAddress.to &&
                            this.state.boxAddress.to.addressLine1
                              ? this.state.boxAddress.to.addressLine1
                              : ""
                          }
                        />
                        {boxAddressError &&
                        boxAddressError.to &&
                        boxAddressError.to.addressLine1 ? (
                          <span className="error_msg">
                            {boxAddressError.to.addressLine1}
                          </span>
                        ) : null}
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>Address 2</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Address 2"
                          onChange={this.handleShippingAddressChanged}
                          name="to.addressLine2"
                          value={
                            this.state.boxAddress.to &&
                            this.state.boxAddress.to.addressLine2
                              ? this.state.boxAddress.to.addressLine2
                              : ""
                          }
                        />
                        {boxAddressError &&
                        boxAddressError.to &&
                        boxAddressError.to.addressLine2 ? (
                          <span className="error_msg">
                            {boxAddressError.to.addressLine2}
                          </span>
                        ) : null}
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col}>
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="City"
                          onChange={this.handleShippingAddressChanged}
                          name="to.city"
                          value={
                            this.state.boxAddress.to &&
                            this.state.boxAddress.to.city
                              ? this.state.boxAddress.to.city
                              : ""
                          }
                        />
                        {boxAddressError &&
                        boxAddressError.to &&
                        boxAddressError.to.city ? (
                          <span className="error_msg">
                            {boxAddressError.to.city}
                          </span>
                        ) : null}
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>State</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="State"
                          onChange={this.handleShippingAddressChanged}
                          name="to.state"
                          value={
                            this.state.boxAddress.to &&
                            this.state.boxAddress.to.state
                              ? this.state.boxAddress.to.state
                              : ""
                          }
                        />
                        {boxAddressError &&
                        boxAddressError.to &&
                        boxAddressError.to.state ? (
                          <span className="error_msg">
                            {boxAddressError.to.state}
                          </span>
                        ) : null}
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>Postal Code</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Postal Code"
                          onChange={this.handleShippingAddressChanged}
                          name="to.postalCode"
                          value={
                            this.state.boxAddress.to &&
                            this.state.boxAddress.to.postalCode
                              ? this.state.boxAddress.to.postalCode
                              : ""
                          }
                        />
                        {boxAddressError &&
                        boxAddressError.to &&
                        boxAddressError.to.postalCode ? (
                          <span className="error_msg">
                            {boxAddressError.to.postalCode}
                          </span>
                        ) : null}
                      </Form.Group>
                    </Form.Row>
                  </Form.Group>
                </Form.Row>

                <div>
                  {uspsLabelOrderInfo.map((item, index) => {
                    return (
                      <div>
                        <div>
                          <div className="usps_flex_start">
                            <p>Customer: {item.customer}</p>
                            <p>Order Total: {item.total}</p>
                          </div>
                        </div>

                        {boxList.length > 0
                          ? boxList.map((box, index) => {
                              return (
                                <USPSBoxForm
                                  errors={boxFormError}
                                  box={box}
                                  index={index}
                                  boxList={boxList}
                                  uspsBoxRates={uspsBoxRates}
                                  handleRemoveBox={this.handleRemoveBox}
                                  handleBoxInputChange={
                                    this.handleBoxInputChange
                                  }
                                  uspsLabelOrderInfo={uspsLabelOrderInfo}
                                />
                              )
                            })
                          : null}
                        {/* 
                            USPS Only allows 25 boxes at a time to be checked 
                            for domestic rates. This will also cap the number of boxes
                            that can be added per order to 25. You're able to add up to 999 boxes during
                            the shipping label process, but this would need additional work.
                        */}
                        {boxList.length < 25 ? (
                          <Form.Group className="usps_form_group">
                            <Button
                              variant="outline-primary"
                              onClick={() => this.handleAddBox()}
                            >
                              Add Box
                            </Button>
                          </Form.Group>
                        ) : null}
                      </div>
                    )
                  })}
                </div>
                <div className="usps_flex_start usps_border_y">
                  <p>Package totals: ${uspsBoxTotals}</p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="footer_btn_wrapper">
                  {uspsBoxRateConfirm ? (
                    <Button
                      variant="warning"
                      onClick={() => this.handleShowUSPSConfirmModal()}
                    >
                      Continue
                    </Button>
                  ) : (
                    <Button type="submit">Get Box Rates</Button>
                  )}
                  <Button
                    variant="dark"
                    onClick={() =>
                      this.setState({
                        boxFormError: [],
                        boxAddressError: [],
                        showUSPSModal: false,
                      })
                    }
                  >
                    Cancel
                  </Button>
                </div>
              </Modal.Footer>
            </form>
          </Modal>

          {/* USPS Label */}
          <Modal
            show={this.state.showUSPSLabelModal}
            onHide={() =>
              this.setState({
                showUSPSLabelModal: false,
              })
            }
            className="cm_order_view dlt_item dlt_item__usps"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <h4>Create USPS Labels</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div id="usps_labels">
                <p>
                  Your{" "}
                  <a
                    className=""
                    href={`https://dashboard.stripe.com/${farmStripeId}/dashboard`}
                    target="_blank"
                  >
                    Stripe Account
                  </a>{" "}
                  will be charged{" "}
                  <span className="usps_total">${uspsBoxTotals}</span> for the
                  following boxes:
                </p>
                <div className="order_items">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="ws-nowrap">Box</th>
                          <th className="ws-nowrap">Weight</th>
                          <th className="ws-nowrap">Length</th>
                          <th className="ws-nowrap">Width</th>
                          <th className="ws-nowrap">Height</th>
                          <th className="ws-nowrap">Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {boxList.map((box, index) => {
                          return (
                            <>
                              <tr key={`order-buy-mp-${index}`}>
                                <td>{index + 1}</td>
                                <td>
                                  {box.boxPounds} lbs, {box.boxOunces} oz
                                </td>
                                <td>{box.boxLength}</td>
                                <td>{box.boxWidth}</td>
                                <td>{box.boxHeight}</td>
                                {uspsBoxRates && uspsBoxRates[index] ? (
                                  // Get the "CommercialRate" instead of the "Rate"
                                  <td>
                                    $
                                    {uspsBoxRates[index].Postage.CommercialRate}
                                  </td>
                                ) : null}
                              </tr>
                            </>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <span className="error_msg">{uspsErrors["labelError"]}</span>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="footer_btn_wrapper">
                {uspsLabelsPrinted ? (
                  <Button
                    variant="warning"
                    onClick={() => {
                      this.handleLabelFinish()
                    }}
                  >
                    Finish
                  </Button>
                ) : (
                  <>
                    {!uspsCreateLabelError ? (
                      <Button
                        variant="warning"
                        type="submit"
                        onClick={() => {
                          this.handleCreateLabels()
                        }}
                      >
                        Create Labels
                      </Button>
                    ) : (
                      <Button
                        variant="warning"
                        onClick={() => {
                          this.setState({
                            showUSPSLabelModal: false,
                            showUSPSModal: true,
                            uspsCreateLabelError: false,
                            uspsErrors: {},
                          })
                        }}
                      >
                        Back
                      </Button>
                    )}

                    <Button
                      variant="dark"
                      onClick={() =>
                        this.setState({
                          // multiValue: [],
                          uspsLabelsPrinted: false,
                          uspsBoxRateConfirm: false,
                          uspsCreateLabelError: false,

                          // USPS Boxes
                          boxList: [
                            {
                              boxLength: "",
                              boxWidth: "",
                              boxHeight: "",
                              boxPounds: "",
                              boxOunces: "",
                            },
                          ],
                          boxAddressError: [],
                          boxFormError: [],
                          uspsBoxRates: [],
                          uspsBoxTotals: 0,

                          // USPS Label
                          showUSPSLabelModal: false,
                        })
                      }
                    >
                      Cancel
                    </Button>
                  </>
                )}
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  const { props, login, user, mapReducer } = state
  return {
    props,
    login,
    user,
    mapReducer,
  }
}

export default connect(mapStateToProps)(Order)
