import "react-phone-input-2/lib/style.css"

import debugFactory from "debug"
import { Button, Col, Form, Modal, Nav, Row, Tab } from "react-bootstrap"
import React, { Component } from "react"
import { farmActions, usersActions } from "../../_actions"

import AddAccount from "./addAccount"
import FileViewer from "react-file-viewer"
import Geocode from "react-geocode"
import { Link } from "react-router-dom"
import PhoneInput from "react-phone-input-2"
import ReactPhoneInput from "react-phone-input-2"
import Sidebar from "../../components/sidebar"
import _ from "lodash"
import { connect } from "react-redux"
import docpng from "../../assets/img/docpng.png"
import pdfpng from "../../assets/img/pdfpng.png"
import siteSetting from "../../config/env"
import { getStripeConnectRedirect } from "../../util/stripe"
import { stripeAccountIdFromUserProfile } from "../../util/user"

const debug = debugFactory("settings")
const isFarmer = siteSetting.isFarmer

// import logger from 'logger';
// import { CustomErrorComponent } from 'custom-error';

const ViewDoc = (path) => {
  let extension
  if (path) {
    extension = path.substr(path.length - 3, path.length).toUpperCase()
  }

  switch (extension) {
    case "PDF":
      return (
        <FileViewer
          fileType={"pdf"}
          filePath={path}
          // errorComponent={CustomErrorComponent}
          // onError={this.onError}
        />
      )

      break
    case "DOC":
      return (
        <div className="item_iframe">
          <iframe
            className={"doc"}
            width="100%"
            height="120"
            frameBorder="0"
            src={`https://docs.google.com/gview?url=${path}&embedded=true`}
          ></iframe>
        </div>
      )

    case "OCX":
      return <FileViewer fileType={"docx"} filePath={path} />

      break
    default:
      return (
        <div
          className="imgas_bg"
          style={{ backgroundImage: `url(${path})` }}
        ></div>
      )
  }
  return "lk"
}

class Settings extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      addressLine1: "",
      addressLine2: "",
      state: "",
      postalCode: "",
      city: "",
      pledge: 0,
      organic: 0,
      minimumOrderValue: 0,
      minimumWholeSaleOrderValue: 0,
      passwordErrorMsg: "",
      emailErrorMsg: "",

      success: "",
      userInfo: [],
      activeType: "Profile",
      isEdit: false,
      isChangePassMode: false,
      insurancePreview: "",
      insuranceDocErrorMsg: "",
      insuranceDoc: "",
      certificatePreview1: "",
      insurancePreview1: "",
      certificatePreview: "",
      certificate: "",
      certificateErrorMsg: "",
      profilePicPreview: "",
      profilePic: "",
      profilePicErrorMsg: "",
      addressId: "",
      userId: localStorage.getItem("userId"),
      address: [],
      firstNameErrorMsg: "",
      phoneErrorMsg: "",
      addressLine1ErrorMsg: "",
      cityErrorMsg: "",
      stateErrorMsg: "",
      postalCodeErrorMsg: "",
      loader: false,
      messagePopup: 0,
      alertPopup: 0,
      emailPopup: 0,
      lat: 95.7129,
      long: 37.0902,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      oldPasswordErrorMsg: "",
      newPasswordErrorMsg: "",
      confirmPasswordErrorMsg: "",
      accountId: "",
      dlt: false,
      errors: "",

      farmInfo: [],
    }
    this.handleCheck = this.handleCheck.bind(this)
    this.handleCheckOrganic = this.handleCheckOrganic.bind(this)
    this.handleCheck2 = this.handleCheck2.bind(this)
    this.handleCheckPledge = this.handleCheckPledge.bind(this)
  }

  componentDidMount() {
    this.getUserInfo()

    if (isFarmer) this.getFarmDetails()
  }

  handleClose = () => {
    this.setState({ dlt: false })
  }

  getFarmDetails = () => {
    let {
      userId,
      farmLogo,
      farmImage,
      farmVideo,
      farmLogoPreview,
      farmVideoPreview,
      farmName,
      farmStatus,
      infoAboutFarm,
      infoAboutFarmer,
      description,
    } = this.state
    this.setState({ errors: "" })

    let self = this
    this.setState({ loader: true })

    let params = `?userId=${userId}&farmerId=${userId}`

    let { dispatch } = this.props
    dispatch(farmActions.getFarmDetails(params)).then((data) => {
      if (!_.isEmpty(data)) {
        let farmInfo = data.responseData.result
        self.setState({ loader: false })

        if (data.statusCode == 1) {
          self.setState({
            farmInfo: farmInfo,
            farmId: farmInfo.length > 0 ? farmInfo[0]._id : "",
          })
        } else {
          self.setState({ errors: data.error.responseMessage })
        }
      }
    })
  }

  handleRedirectFarm() {
    this.props.history.push("/farm")
  }

  redirectTo() {
    let self = this
    let { farmInfo } = self.state
    if (isFarmer && farmInfo.length == 0) {
      this.setState({ dlt: true })
    } else {
      const stripeRedirect = getStripeConnectRedirect()
      window.location.href = stripeRedirect
    }
  }

  getUserInfo = () => {
    this.setState({ errors: "" })

    let self = this
    this.setState({ redirect: false, loader: true })

    let { dispatch } = this.props
    dispatch(usersActions.getUserInfo()).then((data) => {
      debug("get-user-info-resolved")
      debug(data)
      this.setState({ loader: false })
      // let getData = this.props.user;

      if (data.statusCode == 1) {
        let userInfo = data.responseData.userProfile
        localStorage.setItem("userId", userInfo._id)

        const accountId = stripeAccountIdFromUserProfile({
          userProfile: userInfo,
        })

        this.setState({
          userInfo: userInfo,

          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          phone: userInfo.phone,
          email: userInfo.email,
          accountId,
          organic: userInfo.organic,
          pledge: userInfo.pledge,
          address: userInfo.address,
          addressLine1:
            userInfo.address.length > 0
              ? userInfo.address[0].addressLine1
                ? userInfo.address[0].addressLine1
                : ""
              : "",
          addressLine2:
            userInfo.address.length > 0
              ? userInfo.address[0].addressLine2
                ? userInfo.address[0].addressLine2
                : ""
              : "",
          state:
            userInfo.address.length > 0
              ? userInfo.address[0].state
                ? userInfo.address[0].state
                : ""
              : "",
          postalCode:
            userInfo.address.length > 0
              ? userInfo.address[0].postalCode
                ? userInfo.address[0].postalCode
                : ""
              : "",
          city:
            userInfo.address.length > 0
              ? userInfo.address[0].city
                ? userInfo.address[0].city
                : ""
              : "",
          addressId:
            userInfo.address.length > 0
              ? userInfo.address[0]._id
                ? userInfo.address[0]._id
                : ""
              : "",
          minimumOrderValue: userInfo.minimumOrderValue,
          minimumWholeSaleOrderValue: userInfo.minimumWholeSaleOrderValue,
          profilePic: userInfo.profileImage,
          profilePicPreview: userInfo.profileImage,
          certificate: userInfo.certificateImage,
          certificatePreview: userInfo.certificateImage,
          certificatePreview1: userInfo.certificateImage,
          insuranceDoc: userInfo.insuranceImage,
          insurancePreview: userInfo.insuranceImage,
          radius: userInfo.radius,
          emailPopup: userInfo.notificationEmail,
          messagePopup: userInfo.notificationTextMassage,
          alertPopup: userInfo.notificationAppAlert,
          lat: _.isEmpty(userInfo.geoLocation) ? userInfo.geoLocation[0] : 0,
          long: _.isEmpty(userInfo.geoLocation) ? userInfo.geoLocation[1] : 0,
        })
      } else {
        this.setState({ errors: data.error.responseMessage })
      }
    })
  }

  handleProfileValidation = () => {
    let validate = true
    let {
      email,
      profilePic,
      addressLine1,
      city,
      postalCode,
      state,
      firstName,
      phone,
      password,
    } = this.state

    if (profilePic) {
      if (profilePic.size > 5000000) {
        validate = false

        this.setState({
          profilePicErrorMsg: "Profile pic should be less than 5MB.",
        })
      }
    }

    if (firstName === "" || firstName === undefined) {
      validate = false

      this.setState({
        firstNameErrorMsg: "Please enter first name.",
      })
    } else {
      this.setState({
        firstNameErrorMsg: "",
      })
    }
    if (phone) {
      if (phone === "" || phone === undefined) {
        validate = false

        this.setState({
          phoneErrorMsg: "Please enter phone number.",
        })
      } else if (phone.length > 3 && phone.length < 10) {
        validate = false
        this.setState({ phoneErrorMsg: "Please enter a valid mobile number." })
      } else {
        this.setState({
          phoneErrorMsg: "",
        })
      }
    }
    // if (addressLine1 === '' || addressLine1 === undefined) {
    //     validate = false;
    //     console.log(this.state, "4")
    //
    //     this.setState({
    //         addressLine1ErrorMsg: 'Please enter phone number.'
    //     })
    // } else {
    //     this.setState({
    //         addressLine1ErrorMsg: ''
    //     })
    // }
    //
    //
    // if (city === '' || city === undefined) {
    //     validate = false;
    //     console.log(this.state, "5")
    //
    //     this.setState({
    //         cityErrorMsg: 'Please enter city.'
    //     })
    // } else {
    //     this.setState({
    //         cityErrorMsg: ''
    //     })
    // }
    //
    // if (state === '' || state === undefined) {
    //     validate = false;
    //     console.log(this.state, "6")
    //
    //     this.setState({
    //         stateErrorMsg: 'Please enter state.'
    //     })
    // } else {
    //     this.setState({
    //         stateErrorMsg: ''
    //     })
    // }
    //
    // if (postalCode === '' || postalCode === undefined) {
    //     validate = false;
    //     console.log(this.state, "7")
    //
    //     this.setState({
    //         postalCodeErrorMsg: 'Please enter postal code.'
    //     })
    // } else {
    //     this.setState({
    //         postalCodeErrorMsg: ''
    //     })
    // }
    //

    return validate
  }

  handleDocValidation = () => {
    let validate = true
    let {
      insuranceDoc,
      certificate,
      ins,
      city,
      postalCode,
      state,
      firstName,
      phone,
      password,
    } = this.state

    if (insuranceDoc === "" || insuranceDoc === undefined) {
      validate = false
      this.setState({
        insuranceDocErrorMsg: "Please select insurance.",
      })
    }

    if (certificate === "" || certificate === undefined) {
      validate = false
      this.setState({
        certificateErrorMsg: "Please select certificate.",
      })
    }

    return validate
  }

  handleChanged = (e) => {
    let self = this
    let { addressLine1, addressLine2, city, state, postalCode } = this.state

    if (e.target.name === "addressLine1") {
      addressLine1 = e.target.value
    }
    if (e.target.name === "addressLine2") {
      addressLine2 = e.target.value
    }

    if (e.target.name === "city") {
      city = e.target.value
    }
    if (e.target.name === "state") {
      state = e.target.value
    }
    if (e.target.name === "postalCode") {
      postalCode = e.target.value
    }

    let addr =
      addressLine1 +
      " " +
      addressLine2 +
      " " +
      city +
      " " +
      state +
      " " +
      postalCode
    if (
      e.target.name === "addressLine1" ||
      e.target.name === "addressLine2" ||
      e.target.name === "city" ||
      e.target.name === "state" ||
      e.target.name === "postalCode"
    ) {
      Geocode.fromAddress(addr).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location

          self.setState({ lat: lat, lng: lng })
        },
        (error) => {
          console.error(error)
        }
      )
    }

    this.setState({
      [e.target.name]: e.target.value,
      errors: "",
      success: "",
    })
  }

  handleInsuranceUpload = (event) => {
    let reader = new FileReader()
    let file = event.target.files[0]

    let { valiDate } = this.state

    reader.addEventListener(
      "load",
      () => {
        this.setState({ insurancePreview: reader.result }, function () {
          if (file) {
            if (
              (file && file.type === "image/jpeg") ||
              (file && file.type == "image/jpg") ||
              (file && file.type === "image/gif") ||
              (file && file.type == "image/x-icon") ||
              file.type === "image/png" ||
              file.type === "image/svg+xml" ||
              file.type == "image/webp"
            ) {
              this.setState({
                insuranceDoc: file,
                insuranceDocErrorMsg: "",
                insurancePreview1: reader.result,
                insurancePreview: reader.result,
              })
            } else if (file.type === "application/pdf") {
              this.setState({
                insurancePreview: pdfpng,
                insurancePreview1: pdfpng,
                insuranceDoc: file,
                insuranceDocErrorMsg: "",
              })
            } else if (
              file.type == "application/msword" ||
              file.type ==
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            ) {
              this.setState({
                insuranceDoc: file,
                insuranceDocErrorMsg: "",
                insurancePreview: docpng,
                insurancePreview1: docpng,
              })
            } else {
              valiDate = false
              this.setState({
                insuranceDocErrorMsg: "File type not supported.",
              })
            }
          }
        })
      },
      false
    )
    if (file) {
      reader.readAsDataURL(file)
    }
    // if (file.type === 'image/jpeg' || file.type == "image/jpg" || file.type === 'image/gif' || file.type == "image/x-icon" || file.type === 'image/png' || file.type === 'image/svg+xml' || file.type == "image/webp") {
    //     this.setState({
    //         insuranceDoc: event.target.files[0],
    //         insuranceDocErrorMsg: '',
    //         insurancePreview1: this.state.insurancePreview
    //
    //     });
    // } else if (file.type === "application/pdf") {
    //     this.setState({
    //         insurancePreview: pdfpng,
    //         insurancePreview1: pdfpng,
    //         insuranceDoc: event.target.files[0],
    //         insuranceDocErrorMsg: ''
    //
    //
    //     });
    // } else if (file.type == "application/msword" || file.type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
    //     this.setState({
    //         insurancePreview: docpng,
    //         insurancePreview1: docpng,
    //         insuranceDoc: event.target.files[0],
    //         insuranceDocErrorMsg: ''
    //
    //     });
    // } else {
    //     valiDate = false;
    //     this.setState({ insuranceDocErrorMsg: 'File type not supported.' })
    //
    // }
  }

  handleCertificateUpload = (event) => {
    let reader = new FileReader()
    let file = event.target.files[0]

    let { valiDate } = this.state

    reader.addEventListener(
      "load",
      () => {
        this.setState({ certificatePreview: reader.result }, function () {
          if (
            (file && file.type === "image/jpeg") ||
            (file && file.type == "image/jpg") ||
            (file && file.type === "image/gif") ||
            (file && file.type == "image/x-icon") ||
            file.type === "image/png" ||
            file.type === "image/svg+xml" ||
            file.type == "image/webp"
          ) {
            this.setState({
              // certificatePreview: reader.result,
              certificatePreview1: reader.result,

              certificate: file,
              certificateErrorMsg: "",
            })
          } else if (file.type === "application/pdf") {
            this.setState({
              certificatePreview: pdfpng,
              certificatePreview1: pdfpng,
              certificate: file,
              certificateErrorMsg: "",
            })
          } else if (
            file.type == "application/msword" ||
            file.type ==
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ) {
            this.setState({
              certificatePreview: docpng,
              certificatePreview1: docpng,
              certificate: file,
              certificateErrorMsg: "",
            })
          } else {
            valiDate = false
            this.setState({ certificateErrorMsg: "File type not supported." })
          }
        })
      },
      false
    )
    if (file) {
      reader.readAsDataURL(file)
    }
  }

  viewMode = () => {
    this.setState({
      isEdit: false,
      editView: false,
      isChangePassMode: false,
      errors: "",
      success: "",
    })
  }

  editMode = () => {
    this.setState({ isEdit: true, editView: true })
  }

  ChangePassMode = () => {
    this.setState({ isChangePassMode: true })
  }
  handleEditSubmit = (e) => {
    let {
      addressLine1,
      addressLine2,
      city,
      state,
      postalCode,
      lat,
      lng,
      radius,
      profilePic,
      messagePopup,
      alertPopup,
      emailPopup,
      email,
      firstName,
      lastName,
      address,
      phone,
      minimumOrderValue,
      minimumWholeSaleOrderValue,
      addressId,
      userId,
    } = this.state
    this.setState({ errors: "" })
    e.preventDefault()
    let { dispatch } = this.props

    let self = this
    // let {addressLine1, addressLine2, city, state, postalCode, lat, lng} = this.props.mapReducer

    // console.log(addressLine1, addressLine2, city, state, postalCode, lat, lng, "mapreducer")
    if (this.handleProfileValidation()) {
      this.setState({ lat, lng, loader: true })

      let obj = new FormData()
      obj.append("profileImage", profilePic)
      obj.append("email", email)
      obj.append("firstName", firstName)
      obj.append("lastName", lastName)
      if (lat) obj.append("lat", lat)
      if (lng) obj.append("long", lng)
      obj.append("phone", phone.length > 3 ? phone : "")
      obj.append("minimumOrderValue", minimumOrderValue ? minimumOrderValue : 0)
      obj.append(
        "minimumWholeSaleOrderValue",
        minimumWholeSaleOrderValue ? minimumWholeSaleOrderValue : 0
      )
      obj.append("userId", userId)
      obj.append("radius", radius)
      obj.append("notificationEmail", emailPopup ? emailPopup : 0)
      obj.append("notificationTextMassage", messagePopup ? messagePopup : 0)
      // obj.append('notificationAppAlert', alertPopup ? alertPopup : 0);

      dispatch(usersActions.editProfile(obj)).then((data) => {
        self.setState({ loader: false })
        if (data.statusCode == 1) {
          // if (_.isEmpty(this.props.mapReducer)) {
          //     self.setState({loader: false, isEdit: false})
          //     self.getUserInfo()
          // } else {
          let obj2

          this.setState(
            { addressLine1, addressLine2, city, state, postalCode, lat, lng },
            () => {
              obj2 = {
                addressId: addressId,
                addressLine1: this.state.addressLine1,
                addressLine2: this.state.addressLine2,
                city: this.state.city,
                state: this.state.state,
                postalCode: this.state.postalCode,
                userId: userId,
              }
              self.editAndAddAddress(obj2)
              self.getUserInfo()
            }
          )
          // }
        } else {
          self.setState({ errors: data.error.responseMessage })
        }
      })
    }
  }

  editAndAddAddress = (val) => {
    let {
      radius,
      profilePic,
      email,
      firstName,
      lastName,
      address,
      phone,
      minimumOrderValue,
      minimumWholeSaleOrderValue,
      addressId,
      userId,
    } = this.state
    this.setState({ errors: "" })
    let { dispatch } = this.props

    let self = this
    let { addressLine1, addressLine2, city, state, postalCode, lat, lng } =
      this.state

    if (!_.isEmpty(address)) {
      dispatch(usersActions.editAddress(val)).then((data) => {
        self.setState({ loader: false })
        if (data.statusCode == 1) {
          self.setState({ isEdit: false })
          self.getUserInfo()
        } else {
          self.setState({ errors: data.error.responseMessage })
        }
      })
    } else {
      dispatch(usersActions.addAddress(val)).then((data) => {
        self.setState({ loader: false })
        if (data.statusCode == 1) {
          self.setState({ isEdit: false })
          self.getUserInfo()
        } else {
          self.setState({ errors: data.error.responseMessage })
        }
      })
    }
  }

  handleUploadSubmit = (e) => {
    let { certificate, insuranceDoc, userId, organic, pledge } = this.state
    this.setState({ errors: "" })
    e.preventDefault()

    let self = this
    if (this.handleDocValidation()) {
      let obj = new FormData()

      obj.append("insuranceImage", insuranceDoc)
      obj.append("certificateImage", certificate)
      obj.append("pledge", pledge ? pledge : 0)
      obj.append("organic", organic ? organic : 0)
      obj.append("userId", userId)

      self.setState({ loader: true })

      let { dispatch } = this.props
      dispatch(usersActions.uploadDocument(obj)).then((data) => {
        self.setState({ loader: false })

        if (data.statusCode == 1) {
          self.setState({ isEdit: false })
          self.getUserInfo()
        } else {
          self.setState({ errors: data.error.responseMessage })
        }
      })
    }
  }

  handlePicUpload = (event) => {
    let reader = new FileReader()
    let file = event.target.files[0]

    let { valiDate } = this.state

    reader.addEventListener(
      "load",
      () => {
        this.setState({ profilePicPreview: reader.result }, function () {})
      },
      false
    )
    if (file) {
      reader.readAsDataURL(file)
    }
    if (file) {
      if (
        file.type === "image/jpeg" ||
        file.type === "image/gif" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml"
      ) {
        if (file.size > 5000000) {
          valiDate = false
          this.setState({
            profilePic: event.target.files[0],
            profilePicErrorMsg: "Profile pic size should be less than 5MB.",
          })
        } else {
          this.setState({
            profilePic: event.target.files[0],
            profilePicErrorMsg: "",
          })
        }
      } else {
        valiDate = false
        this.setState({ profilePicErrorMsg: "File type not supported." })
      }
    }
  }

  handleCheck(e) {
    let { messagePopup } = this.state
    if (e == 0) {
      this.setState({ messagePopup: 1, agreeErrorMsg: "" })
    } else {
      this.setState({ messagePopup: 0 })
    }
  }

  // handleCheck1(e) {
  //     let {alertPopup} = this.state;
  //     if (e == 0) {
  //         this.setState({alertPopup: 1, agreeErrorMsg: ''})
  //     } else {
  //         this.setState({alertPopup: 0})
  //     }
  // }

  handleCheck2(e) {
    let { emailPopup } = this.state

    if (e == 0) {
      this.setState({ emailPopup: 1, agreeErrorMsg: "" })
    } else {
      this.setState({ emailPopup: 0 })
    }
  }
  handleCheckOrganic(e) {
    let { messagePopup } = this.state
    if (e == 0) {
      this.setState({ organic: 1, agreeErrorMsg: "" })
    } else {
      this.setState({ organic: 0 })
    }
  }
  handleCheckPledge(e) {
    let { messagePopup } = this.state
    if (e == 0) {
      this.setState({ pledge: 1, agreeErrorMsg: "" })
    } else {
      this.setState({ pledge: 0 })
    }
  }
  activeType = (e) => {
    if (e == "Profile") {
      this.setState({ activeType: "Profile" }, () => this.getUserInfo())
    } else if (e == "Document") {
      this.setState({ activeType: "Document" }, () => this.getUserInfo())
    } else if (e == "Bank Details") {
      this.setState({ activeType: "Bank Details" }, () => this.getUserInfo())
    } else if (e == "Verification") {
      this.setState({ activeType: "Verification" }, () => this.getUserInfo())
    }
  }
  handleValidation = () => {
    let validate = true
    let { oldPassword, newPassword, confirmPassword } = this.state
    if (oldPassword === "" || oldPassword === undefined) {
      validate = false
      this.setState({
        oldPasswordErrorMsg: "Old password is required.",
      })
    } else if (oldPassword.length < 5) {
      validate = false
      this.setState({
        oldPasswordErrorMsg: "Old password must contain at least 6 characters.",
      })
    } else {
      this.setState({
        oldPasswordErrorMsg: "",
      })
    }

    if (newPassword === "" || newPassword === undefined) {
      validate = false
      this.setState({
        newPasswordErrorMsg: "New password is required.",
      })
    } else if (newPassword.length < 5) {
      validate = false
      this.setState({
        newPasswordErrorMsg: "New password must contain at least 6 characters.",
      })
    } else {
      this.setState({
        newPasswordErrorMsg: "",
      })
    }

    if (confirmPassword === "" || confirmPassword === undefined) {
      validate = false
      this.setState({
        confirmPasswordErrorMsg: "Confirm password is required.",
      })
    } else if (confirmPassword !== newPassword) {
      validate = false
      this.setState({
        confirmPasswordErrorMsg:
          "New password and Confirm password does not match.",
      })
    } else if (oldPassword === newPassword) {
      validate = false
      this.setState({
        confirmPasswordErrorMsg:
          "Old Password & New Password should not be same!",
      })
    } else {
      this.setState({
        confirmPasswordErrorMsg: "",
      })
    }

    return validate
  }
  // changePassword() {

  changePassword = (event) => {
    event.preventDefault()
    var self = this

    if (this.handleValidation()) {
      let { confirmPassword, newPassword, oldPassword } = self.state

      let params = {
        newPassword: newPassword,
        oldPassword: oldPassword,
      }
      this.setState({ loader: true, errors: "", success: "" })

      let { dispatch } = this.props
      dispatch(usersActions.changePassword(params)).then((data) => {
        self.setState({ loader: false })
        if (data.statusCode == 1) {
          self.setState({
            loader: false,
            isEdit: false,
            success: data.responseData.message,
            newPassword: "",
            oldPassword: "",
            confirmPassword: "",
          })
        } else {
          self.setState({ errors: data.error.responseMessage })
        }
      })
    }
  }
  onKeyPress(e) {
    e = e || window.event
    var charCode = typeof e.which == "undefined" ? e.keyCode : e.which
    var charStr = String.fromCharCode(charCode)

    if (!charStr.match(/^[0-9]+$/)) e.preventDefault()
  }

  // const Settings = () => {
  render() {
    let {
      farmInfo,
      dlt,
      pledge,
      organic,
      oldPassword,
      newPassword,
      confirmPassword,
      oldPasswordErrorMsg,
      newPasswordErrorMsg,
      confirmPasswordErrorMsg,

      editView,
      isChangePassMode,
      accountId,
      radius,
      activeType,
      email,
      firstName,
      lastName,
      password,
      phone,
      passwordErrorMsg,
      errors,
      success,
      userInfo,
      addressLine1,
      addressLine2,
      state,
      minimumOrderValue,
      minimumWholeSaleOrderValue,
      postalCode,
      city,
      isEdit,
      insurancePreview,
      insuranceDocErrorMsg,
      insuranceDoc,
      certificatePreview1,
      certificatePreview,
      certificate,
      certificateErrorMsg,
      profilePicPreview,
      profilePic,

      profilePicErrorMsg,
      emailErrorMsg,
      firstNameErrorMsg,
      phoneErrorMsg,
      addressLine1ErrorMsg,
      cityErrorMsg,
      stateErrorMsg,
      postalCodeErrorMsg,
      loader,
      messagePopup,
      alertPopup,
      emailPopup,
    } = this.state
    return (
      <>
        <Sidebar />

        <div>
          <div className="header_wrapper">
            <div className="header_logo">
              <img src={require("../../assets/img/OFFICIAL LOGO.png")} />
            </div>
            <div className="header_search"></div>
            <div className="bell_icon">
              <i className="fas fa-bell"></i>
            </div>
          </div>

          <div className="body_container">
            <div className="body_inner_wrapper">
              {this.state.loader ? (
                <div className="loader_wrapper">
                  <div class="spinner-border text-info" role="status"></div>
                </div>
              ) : (
                <>
                  <header className="header_content">
                    <p>Settings</p>
                  </header>
                  <div className="main_content">
                    <div className="farm_page setting_wrapper">
                      <Tab.Container
                        defaultActiveKey="Profile"
                        onSelect={(e) => this.activeType(e)}
                        activeKey={activeType}
                      >
                        <Row>
                          <Col md={3}>
                            <Nav variant="pills" className="flex-column">
                              <Nav.Item>
                                <Nav.Link eventKey="Profile">Profile</Nav.Link>
                              </Nav.Item>
                              {isFarmer && (
                                <Nav.Item>
                                  <Nav.Link eventKey="Document">
                                    Document
                                  </Nav.Link>
                                </Nav.Item>
                              )}
                              <Nav.Item>
                                <Nav.Link eventKey="Bank Details">
                                  Bank Details
                                </Nav.Link>
                              </Nav.Item>
                              {isFarmer && !accountId && (
                                <Nav.Item>
                                  <Nav.Link eventKey="Verification">
                                    Verification
                                  </Nav.Link>
                                </Nav.Item>
                              )}
                            </Nav>
                          </Col>
                          <Col md={9}>
                            <Tab.Content>
                              {isEdit ? (
                                <Tab.Pane eventKey="Profile">
                                  <form onSubmit={this.handleEditSubmit}>
                                    <div className="u_img mb-5">
                                      {profilePicPreview ? (
                                        <img src={profilePicPreview} />
                                      ) : (
                                        <span className="profile_icon_Admin ">
                                          <img
                                            src={require("../../assets/img/logo.png")}
                                          />
                                        </span>
                                      )}

                                      <span className="img_icon">
                                        <span className="file_input">
                                          <input
                                            type="file"
                                            className="cm_hide_input"
                                            accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                            onChange={this.handlePicUpload}
                                          />
                                        </span>
                                        <img
                                          src={require("../../assets/img/imgicon.png")}
                                        />
                                      </span>
                                    </div>
                                    {profilePicErrorMsg ? (
                                      <div className="error text-center ">
                                        {profilePicErrorMsg}
                                      </div>
                                    ) : null}
                                    {profilePicErrorMsg ? <br /> : ""}

                                    <Row>
                                      <Col lg="2" md="4">
                                        <p>First Name:</p>
                                      </Col>
                                      <Col md="3">
                                        <Form.Control
                                          type="text"
                                          onChange={this.handleChanged}
                                          name="firstName"
                                          value={firstName}
                                          placeholder="First Name "
                                        />
                                        {firstNameErrorMsg ? (
                                          <div className="error">
                                            {firstNameErrorMsg}
                                          </div>
                                        ) : null}
                                        {/*<Form.Control type="text" placeholder="Star Light"/>*/}
                                      </Col>
                                      <Col lg="2" md="2">
                                        <p>Last Name:</p>
                                      </Col>
                                      <Col md="3">
                                        <Form.Control
                                          type="text"
                                          onChange={this.handleChanged}
                                          name="lastName"
                                          value={lastName}
                                          placeholder="Last Name "
                                        />
                                        {/*{profilePicErrorMsg ?*/}
                                        {/*    <div className="error">{wholeselUnitErrorMsg}</div> : null}*/}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg="2" md="4">
                                        <p>Email:</p>
                                      </Col>
                                      <Col md="8">
                                        <Form.Control
                                          type="email"
                                          onChange={this.handleChanged}
                                          name="email"
                                          disabled
                                          value={email}
                                          placeholder="Email Address"
                                        />
                                        {emailErrorMsg ? (
                                          <div className="error">
                                            {emailErrorMsg}
                                          </div>
                                        ) : null}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg="2" md="4">
                                        <p>Phone:</p>
                                      </Col>
                                      <Col md="8">
                                        {/*<ReactPhoneInput*/}
                                        {/*    value={phone}*/}
                                        {/*    country='us'*/}
                                        {/*    autoFormat="off"*/}
                                        {/*    disableCountryCode='us'*/}
                                        {/*    placeholder="Please enter phone number"*/}
                                        {/*    onChange={(phone) => this.setState({phone: phone})}*/}
                                        {/*/>*/}

                                        <div className="cm_phnnumber">
                                          <PhoneInput
                                            country={"us"}
                                            className="form-control custom-number"
                                            value={phone}
                                            placeholder="Please enter phone number"
                                            onChange={(phone) =>
                                              this.setState({ phone })
                                            }
                                          />
                                        </div>
                                        {phoneErrorMsg ? (
                                          <div className="error">
                                            {phoneErrorMsg}
                                          </div>
                                        ) : null}
                                      </Col>
                                    </Row>

                                    {isFarmer && (
                                      <Row>
                                        <Col lg="2" md="4">
                                          <p>Notifications:</p>
                                        </Col>
                                        <Col lg="2" md="5">
                                          <Form.Check
                                            type="checkbox"
                                            checked={emailPopup ? true : false}
                                            value={this.state.emailPopup}
                                            name="emailPopup"
                                            onChange={this.handleCheck2.bind(
                                              this,
                                              emailPopup
                                            )}
                                            label="Email"
                                          />
                                        </Col>
                                        <Col lg="4" md="5">
                                          <Form.Check
                                            type="checkbox"
                                            checked={
                                              messagePopup ? true : false
                                            }
                                            value={this.state.messagePopup}
                                            name="messagePopup"
                                            onChange={this.handleCheck.bind(
                                              this,
                                              messagePopup
                                            )}
                                            label="Text Message"
                                          />
                                        </Col>
                                        {/*<Col md="2">*/}
                                        {/*    <Form.Check type="checkbox"*/}
                                        {/*                checked={alertPopup ? true : false}*/}
                                        {/*                value={this.state.alertPopup}*/}
                                        {/*                name="alertPopup"*/}
                                        {/*                onChange={this.handleCheck1.bind(this, alertPopup)}*/}
                                        {/*                label="App Alert"/>*/}
                                        {/*</Col>*/}
                                      </Row>
                                    )}
                                    {isFarmer && (
                                      <Row>
                                        <Col lg="2" md="4">
                                          <p>Distribution Radius:</p>
                                        </Col>
                                        <Col md="8">
                                          <Form.Control
                                            type="number"
                                            name="radius"
                                            step="any"
                                            value={radius}
                                            min={0}
                                            onChange={this.handleChanged}
                                            placeholder="Distribution Radius"
                                          />
                                        </Col>
                                      </Row>
                                    )}

                                    {isFarmer && (
                                      <Row>
                                        <Col lg="2" md="4">
                                          <p>Minimum Wholesale Order Value:</p>
                                        </Col>

                                        <Col md="8">
                                          <Form.Control
                                            type="number"
                                            step="any"
                                            onChange={this.handleChanged}
                                            min={0}
                                            name="minimumWholeSaleOrderValue"
                                            value={minimumWholeSaleOrderValue}
                                            placeholder="0"
                                          />
                                        </Col>
                                      </Row>
                                    )}

                                    {isFarmer && (
                                      <Row>
                                        <Col lg="2" md="5">
                                          <p>Minimum Consumer Order Value:</p>
                                        </Col>

                                        <Col md="8">
                                          <Form.Control
                                            type="number"
                                            step="any"
                                            min={0}
                                            onChange={this.handleChanged}
                                            name="minimumOrderValue"
                                            value={minimumOrderValue}
                                            placeholder="0"
                                          />
                                        </Col>
                                      </Row>
                                    )}

                                    <Form.Group>
                                      <div>
                                        <Row>
                                          <Col lg="2" md="4">
                                            {" "}
                                            <label htmlFor="">
                                              Address Line 1
                                            </label>
                                          </Col>
                                          <Col md="8">
                                            <input
                                              type="text"
                                              name="addressLine1"
                                              className="form-control"
                                              placeholder="Address Line 1"
                                              onChange={this.handleChanged}
                                              value={this.state.addressLine1}
                                            />
                                          </Col>
                                        </Row>
                                        {/*</div>*/}
                                        <Row>
                                          <Col lg="2" md="4">
                                            <label htmlFor="">
                                              Address Line 2
                                            </label>
                                          </Col>
                                          <Col md="8">
                                            {" "}
                                            <input
                                              type="text"
                                              name="addressLine2"
                                              className="form-control"
                                              placeholder="Address Line 2"
                                              onChange={this.handleChanged}
                                              value={this.state.addressLine2}
                                            />
                                          </Col>{" "}
                                        </Row>

                                        <Row>
                                          <Col lg="2" md="4">
                                            <label htmlFor="">City</label>
                                          </Col>
                                          <Col md="8">
                                            <input
                                              type="text"
                                              name="city"
                                              className="form-control"
                                              placeholder="City"
                                              onChange={this.handleChanged}
                                              value={this.state.city}
                                            />
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col lg="2" md="4">
                                            <label htmlFor="">State</label>
                                          </Col>
                                          <Col md="8">
                                            <input
                                              type="text"
                                              name="state"
                                              className="form-control"
                                              placeholder="State"
                                              onChange={this.handleChanged}
                                              value={this.state.state}
                                            />
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col lg="2" md="4">
                                            <label htmlFor="">
                                              Postal Code{" "}
                                            </label>
                                          </Col>
                                          <Col md="8">
                                            <input
                                              type="number"
                                              name="postalCode"
                                              placeholder="Postal Code"
                                              min={0}
                                              className="form-control"
                                              onChange={this.handleChanged}
                                              value={this.state.postalCode}
                                            />
                                          </Col>
                                        </Row>
                                      </div>
                                    </Form.Group>
                                    <Row>
                                      <Col md="10" className="text-sm-right">
                                        <Button
                                          type="submit"
                                          variant="warning"
                                          className="mr-2"
                                        >
                                          Save
                                        </Button>
                                        &nbsp;
                                        <Button
                                          onClick={this.viewMode}
                                          variant="dark"
                                        >
                                          Cancel
                                        </Button>
                                      </Col>
                                    </Row>
                                  </form>
                                </Tab.Pane>
                              ) : isChangePassMode ? (
                                <Tab.Pane eventKey="Profile">
                                  <div class="error">
                                    {errors ? errors : ""}
                                  </div>
                                  <div class="success">
                                    {success ? success : ""}
                                  </div>
                                  <form onSubmit={this.changePassword}>
                                    <Row>
                                      <Col lg="2" md="3">
                                        <p>Old Password:</p>
                                      </Col>
                                      <Col md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={this.handleChanged}
                                          name="oldPassword"
                                          value={oldPassword}
                                          maxLength="30"
                                          placeholder="Old Password "
                                        />
                                        {oldPasswordErrorMsg ? (
                                          <div className="error">
                                            {oldPasswordErrorMsg}
                                          </div>
                                        ) : null}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg="2" md="3">
                                        <p>New Password:</p>
                                      </Col>
                                      <Col md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={this.handleChanged}
                                          name="newPassword"
                                          value={newPassword}
                                          maxLength="30"
                                          placeholder="New Password  "
                                        />
                                        {newPasswordErrorMsg ? (
                                          <div className="error">
                                            {newPasswordErrorMsg}
                                          </div>
                                        ) : null}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg="2" md="3">
                                        <p>Confirm Password:</p>
                                      </Col>
                                      <Col md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={this.handleChanged}
                                          name="confirmPassword"
                                          value={confirmPassword}
                                          maxLength="30"
                                          placeholder="Confirm Password "
                                        />
                                        {confirmPasswordErrorMsg ? (
                                          <div className="error">
                                            {confirmPasswordErrorMsg}
                                          </div>
                                        ) : null}
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col
                                        md="10"
                                        className="text-right cmbtngrp"
                                      >
                                        <Button
                                          type="submit"
                                          variant="warning"
                                          className="mr-2"
                                        >
                                          Save
                                        </Button>

                                        <Button
                                          onClick={this.viewMode}
                                          variant="dark"
                                        >
                                          Cancel
                                        </Button>
                                      </Col>
                                    </Row>
                                  </form>
                                </Tab.Pane>
                              ) : (
                                <Tab.Pane eventKey="Profile">
                                  <Row className="mobilebtn-setting cmbtngrp">
                                    <Col lg="10" md="12">
                                      <Button
                                        onClick={this.ChangePassMode}
                                        variant="warning"
                                      >
                                        Change Password
                                      </Button>
                                      {accountId && (
                                        <Button
                                          onClick={() =>
                                            this.activeType("Bank Details")
                                          }
                                          className="text-light mr-2"
                                          variant="h-primary"
                                        >
                                          Account Details
                                        </Button>
                                      )}
                                      {!accountId && (
                                        <Button
                                          // type="submit"
                                          onClick={() => this.redirectTo()}
                                          // disabled={_.isEmpty(farmInfo) ? true : false}
                                          className="text-light mr-2"
                                          variant="h-primary"
                                        >
                                          Connect Bank Account
                                        </Button>
                                      )}
                                    </Col>
                                  </Row>

                                  <div className="u_img mb-5">
                                    {profilePic ? (
                                      <img src={profilePic} />
                                    ) : (
                                      <img
                                        src={require("../../assets/img/logo.png")}
                                      />
                                    )}
                                  </div>
                                  <Row className="view_sec">
                                    <Col lg="2" md="4">
                                      <p>First Name:</p>
                                    </Col>
                                    <Col md="3">
                                      <Form.Control
                                        type="text"
                                        disabled
                                        name="firstName"
                                        value={firstName}
                                        placeholder="First Name "
                                      />
                                    </Col>
                                    <Col lg="2" md="2">
                                      <p>Last Name:</p>
                                    </Col>
                                    <Col md="3">
                                      <Form.Control
                                        type="text"
                                        disabled
                                        onChange={this.handleChanged}
                                        name="lastName"
                                        value={lastName}
                                        placeholder="Last Name "
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="2" md="4">
                                      <p>Email:</p>
                                    </Col>
                                    <Col md="8">
                                      <Form.Control
                                        type="email"
                                        onChange={this.handleChanged}
                                        name="email"
                                        disabled
                                        value={email}
                                        placeholder="Email Address"
                                      />
                                    </Col>
                                  </Row>
                                  {phone ? (
                                    <Row>
                                      <Col lg="2" md="4">
                                        <p>Phone:</p>
                                      </Col>
                                      <Col md="8">
                                        <div className="cm_phnnumber">
                                          <ReactPhoneInput
                                            country="us"
                                            autoFormat="off"
                                            value={phone}
                                            disabled
                                            placeholder="Phone Number"
                                            onChange={(phone) =>
                                              this.setState({ phone: phone })
                                            }
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  ) : (
                                    ""
                                  )}
                                  {addressLine1 ? (
                                    <Row>
                                      <Col lg="2" md="4">
                                        <p>Address Line 1:</p>
                                      </Col>
                                      <Col md="8">
                                        <Form.Control
                                          type="text"
                                          disabled
                                          onChange={this.handleChanged}
                                          name="addressLine1"
                                          value={addressLine1}
                                          placeholder="Address Line 1"
                                        />
                                      </Col>
                                    </Row>
                                  ) : (
                                    ""
                                  )}
                                  {addressLine2 ? (
                                    <Row>
                                      <Col lg="2" md="4">
                                        <p>Address Line 2:</p>
                                      </Col>
                                      <Col md="8">
                                        <Form.Control
                                          type="text"
                                          disabled
                                          onChange={this.handleChanged}
                                          name="addressLine2"
                                          value={addressLine2}
                                          placeholder="Address Line 2 "
                                        />
                                      </Col>
                                    </Row>
                                  ) : (
                                    ""
                                  )}

                                  {city ? (
                                    <Row>
                                      <Col lg="2" md="4">
                                        <p>City:</p>
                                      </Col>
                                      <Col md="8">
                                        <Form.Control
                                          type="text"
                                          disabled
                                          onChange={this.handleChanged}
                                          name="city"
                                          value={city}
                                          placeholder="City "
                                        />
                                      </Col>
                                    </Row>
                                  ) : (
                                    ""
                                  )}
                                  {state ? (
                                    <Row>
                                      <Col lg="2" md="4">
                                        <p>State:</p>
                                      </Col>
                                      <Col md="8">
                                        <Form.Control
                                          type="text"
                                          disabled
                                          onChange={this.handleChanged}
                                          name="state"
                                          value={state}
                                          placeholder="State "
                                        />
                                      </Col>
                                    </Row>
                                  ) : (
                                    ""
                                  )}

                                  {postalCode ? (
                                    <Row>
                                      {" "}
                                      <Col lg="2" md="4">
                                        <p>Postal Code:</p>
                                      </Col>
                                      <Col md="8">
                                        <Form.Control
                                          type="number"
                                          disabled
                                          onChange={this.handleChanged}
                                          name="postalCode"
                                          value={postalCode}
                                          placeholder="Postal Code "
                                        />
                                      </Col>
                                    </Row>
                                  ) : (
                                    ""
                                  )}
                                  {isFarmer &&
                                    (emailPopup == 1 || messagePopup == 1) && (
                                      <Row>
                                        <Col lg="2" md="4">
                                          <p>Notifications:</p>
                                        </Col>

                                        <Col lg="2" md="3">
                                          <Form.Check
                                            type="checkbox"
                                            checked={emailPopup ? true : false}
                                            value={this.state.emailPopup}
                                            name="emailPopup"
                                            onChange={this.handleCheck2.bind(
                                              this,
                                              emailPopup
                                            )}
                                            label="Email"
                                            disabled
                                          />
                                        </Col>
                                        <Col lg="4" md="5">
                                          <Form.Check
                                            type="checkbox"
                                            checked={
                                              messagePopup ? true : false
                                            }
                                            value={this.state.messagePopup}
                                            name="messagePopup"
                                            onChange={this.handleCheck.bind(
                                              this,
                                              messagePopup
                                            )}
                                            label="Text Message"
                                            disabled
                                          />
                                        </Col>
                                        {/*<Col md="2">*/}
                                        {/*    <Form.Check type="checkbox"*/}
                                        {/*                checked={alertPopup ? true : false}*/}
                                        {/*                value={this.state.alertPopup}*/}
                                        {/*                name="alertPopup"*/}
                                        {/*                onChange={this.handleCheck1.bind(this, alertPopup)}*/}
                                        {/*                label="App Alert"/>*/}
                                        {/*</Col>*/}
                                      </Row>
                                    )}
                                  {radius ? (
                                    <Row>
                                      <Col lg="2" md="3">
                                        <p> Distribution Radius:</p>
                                      </Col>
                                      <Col md="8">
                                        {/*<select value={radius} name="radius"*/}
                                        {/*        onChange={this.handleChanged} disabled*/}
                                        {/*        className="form-control">*/}
                                        {/*    <option>Select</option>*/}
                                        {/*    <option value="5">5 kms</option>*/}
                                        {/*    <option value="10">10 kms</option>*/}
                                        {/*</select>*/}

                                        <Form.Control
                                          type="number"
                                          name="radius"
                                          step="any"
                                          value={radius}
                                          disabled
                                          onChange={this.handleChanged}
                                          placeholder="Distribution Radius"
                                        />
                                      </Col>
                                    </Row>
                                  ) : (
                                    ""
                                  )}
                                  <Row>
                                    <Col lg="2" md="4">
                                      {minimumOrderValue ? (
                                        <p>Minimum Consumer Order Value:</p>
                                      ) : (
                                        ""
                                      )}
                                    </Col>

                                    <Col md="8">
                                      {minimumOrderValue ? (
                                        <Form.Control
                                          type="number"
                                          disabled
                                          step="any"
                                          onChange={this.handleChanged}
                                          name="minimumOrderValue"
                                          value={minimumOrderValue}
                                          placeholder="$0"
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col lg="2" md="3">
                                      {minimumWholeSaleOrderValue ? (
                                        <p>Minimum Wholesale Order Value:</p>
                                      ) : (
                                        ""
                                      )}
                                    </Col>

                                    <Col md="6">
                                      {minimumWholeSaleOrderValue ? (
                                        <Form.Control
                                          type="number"
                                          disabled
                                          step="any"
                                          onChange={this.handleChanged}
                                          name="minimumWholeSaleOrderValue"
                                          value={minimumWholeSaleOrderValue}
                                          placeholder="$0"
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                    <Col md="3">
                                      <Button
                                        onClick={this.editMode}
                                        variant="warning"
                                      >
                                        Edit
                                      </Button>
                                    </Col>
                                  </Row>
                                </Tab.Pane>
                              )}

                              <Tab.Pane eventKey="Document">
                                <form onSubmit={this.handleUploadSubmit}>
                                  <Row>
                                    <Col lg="3" md="6">
                                      <p>Insurance Document:</p>
                                    </Col>
                                    <Col lg="4" md="6">
                                      <div
                                        className="file_uploader bg_size"
                                        // style={{ backgroundImage: `url(${this.state.insurancePreview1})` }}
                                      >
                                        {ViewDoc(insurancePreview)}
                                        {insurancePreview ? (
                                          <>
                                            {/* <div class="overlay"></div> */}

                                            <span className="dwnload-icon">
                                              <a href={insuranceDoc} download>
                                                <i className="fas fa-download"></i>
                                              </a>
                                            </span>
                                            <div className="upload-onicon">
                                              <Form.File
                                                label="Custom file input"
                                                name="insuranceDoc"
                                                lang="en"
                                                onChange={
                                                  this.handleInsuranceUpload
                                                }
                                                custom
                                              />
                                              <i className="fas fa-pen"></i>
                                            </div>
                                          </>
                                        ) : (
                                          <div className="">
                                            <Form.File
                                              label="Custom file input"
                                              name="insuranceDoc"
                                              lang="en"
                                              onChange={
                                                this.handleInsuranceUpload
                                              }
                                              custom
                                            />
                                            <i className="fas fa-plus-circle"></i>
                                          </div>
                                        )}
                                        <br />

                                        {insurancePreview ? (
                                          ""
                                        ) : (
                                          <span className="placeholder">
                                            Upload Image
                                          </span>
                                        )}
                                      </div>
                                      {insuranceDocErrorMsg ? (
                                        <div className="errordoc">
                                          {insuranceDocErrorMsg}
                                        </div>
                                      ) : null}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md="2"></Col>
                                    <Col md="4">
                                      {/*<small>Recommended size 350*170 </small>*/}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="3" md="6">
                                      <p>Certification or Growers Pledge:</p>
                                    </Col>
                                    <Col lg="4" md="6">
                                      <div
                                        className="file_uploader bg_size"
                                        // style={{ backgroundImage: `url(${this.state.certificatePreview1})` }}
                                      >
                                        {ViewDoc(certificatePreview1)}
                                        {/* <div class="overlay"></div> */}
                                        {certificatePreview ? (
                                          <>
                                            {/* <div class="overlay"></div> */}

                                            <span className="dwnload-icon">
                                              <a href={certificate} download>
                                                <i className="fas fa-download"></i>
                                              </a>
                                            </span>
                                            <div className="upload-onicon">
                                              <Form.File
                                                label="Custom file input"
                                                name="certificate"
                                                onChange={
                                                  this.handleCertificateUpload
                                                }
                                                lang="en"
                                                custom
                                              />
                                              <i class="fas fa-pen"></i>
                                            </div>
                                          </>
                                        ) : (
                                          <div className="">
                                            <Form.File
                                              label="Custom file input"
                                              name="certificate"
                                              onChange={
                                                this.handleCertificateUpload
                                              }
                                              lang="en"
                                              custom
                                            />
                                            <i className="fas fa-plus-circle"></i>
                                          </div>
                                        )}
                                        <br />
                                        {certificatePreview ? (
                                          ""
                                        ) : (
                                          <span className="placeholder">
                                            Upload Image
                                          </span>
                                        )}
                                      </div>
                                      {certificateErrorMsg ? (
                                        <div className="errordoc">
                                          {certificateErrorMsg}
                                        </div>
                                      ) : null}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md="2"></Col>
                                    <Col md="4">
                                      {/*<small>Recommended size 350*170 </small>*/}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="5" md="6">
                                      <Form.Check
                                        type="checkbox"
                                        checked={organic ? true : false}
                                        value={this.state.organic}
                                        name="organic"
                                        onChange={this.handleCheckOrganic.bind(
                                          this,
                                          organic
                                        )}
                                        label="USDA Certified Organic"
                                      />
                                    </Col>
                                    <Col lg="5" md="6">
                                      <Form.Check
                                        type="checkbox"
                                        checked={pledge ? true : false}
                                        value={this.state.pledge}
                                        name="messagePopup"
                                        onChange={this.handleCheckPledge.bind(
                                          this,
                                          pledge
                                        )}
                                        label="NOFA Farmer’s Pledge"
                                      />
                                    </Col>
                                  </Row>

                                  <div className="text-right col-md-10">
                                    <Button
                                      type="submit"
                                      className="text-light mr-2"
                                      variant="warning"
                                    >
                                      Save
                                    </Button>
                                    <Link
                                      onClick={this.editMode}
                                      className="text-light mr-2"
                                      variant="warning"
                                    >
                                      Edit
                                    </Link>
                                  </div>
                                </form>
                              </Tab.Pane>
                              <Tab.Pane eventKey="Bank Details">
                                <AddAccount />
                                {/*<Row>*/}
                                {/*    <Col md="2">*/}
                                {/*        <p>Account Number:</p>*/}
                                {/*    </Col>*/}
                                {/*    <Col md="8">*/}
                                {/*        <Form.Control type="text"*/}
                                {/*                      placeholder="Enter here"/>*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}
                                {/*<Row>*/}
                                {/*    <Col md="2">*/}
                                {/*        <p>Full Name:</p>*/}
                                {/*    </Col>*/}
                                {/*    <Col md="8">*/}
                                {/*        <Form.Control type="text"*/}
                                {/*                      placeholder="Enter here"/>*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}
                                {/*<Row>*/}
                                {/*    <Col md="2">*/}
                                {/*        <p>Routing Number:</p>*/}
                                {/*    </Col>*/}
                                {/*    <Col md="8">*/}
                                {/*        <Form.Control type="text"*/}
                                {/*                      placeholder="Enter here"/>*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}
                                {/*<div className="text-right col-md-10">*/}
                                {/*    <Button variant="warning">Save</Button>*/}
                                {/*</div>*/}
                              </Tab.Pane>

                              <Tab.Pane eventKey="Verification">
                                <Row>
                                  <Col>
                                    <div className="text-center verify_id">
                                      <p>Your Verification is pending</p>

                                      <div className="file_uploader bg_size">
                                        <p>
                                          Verifying your identity will allow you
                                          to access all our services
                                        </p>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </Tab.Pane>
                            </Tab.Content>
                          </Col>
                        </Row>
                      </Tab.Container>
                    </div>
                  </div>
                  <Modal
                    show={dlt}
                    onHide={this.handleClose}
                    className="cm_order_view dlt_item"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <img
                        height="30px"
                        width="30px"
                        src={require("../../assets/img/farm.png")}
                      />
                      <br />
                      <br />
                      <p>
                        You are required to fill Farm details before connecting
                        your account details.
                      </p>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="footer_btn_wrapper">
                        <Button
                          variant="warning"
                          onClick={(e) => this.handleRedirectFarm()}
                        >
                          Go to Farm
                        </Button>
                        {/*<span><i onClick={(e)=>this.handleDelete(e,item)} class="fas fa-trash-alt"></i></span>*/}

                        <Button variant="dark" onClick={this.handleClose}>
                          Cancel
                        </Button>
                      </div>
                    </Modal.Footer>
                  </Modal>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  const { props, login, user, mapReducer } = state
  return {
    props,
    login,
    user,
    mapReducer,
  }
}

export default connect(mapStateToProps)(Settings)
// export default Settings
